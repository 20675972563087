import React from "react";
import * as Styles from "./styles";

export const Hamburger = ({ active, onClick }) => {
  const handleClick = () => onClick && onClick(!active);
  return (
    <Styles.Wrapper active={active} onClick={handleClick}>
      <Styles.Ingredient active={active} />
      <Styles.Ingredient active={active} />
      <Styles.Ingredient active={active} />
    </Styles.Wrapper>
  );
};
