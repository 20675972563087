import { createStore, applyMiddleware } from "redux";
import { createBrowserHistory } from "history";
import { composeWithDevTools } from "redux-devtools-extension";
import createSagaMiddleware from "redux-saga";
import logger from "redux-logger";
import { routerMiddleware } from "connected-react-router";
import { createRootReducer } from "@app/reducers";
import rootSaga from "@app/sagas";

export const history = createBrowserHistory();

const sagaMiddleware = createSagaMiddleware();

export const store = createStore(
  createRootReducer(history),
  composeWithDevTools(
    applyMiddleware(routerMiddleware(history), logger, sagaMiddleware)
  )
);

sagaMiddleware.run(rootSaga);
