// @ts-check

import { createSelector } from "reselect";
import { createPathSelector } from "@utils/selectorUtils";

const rootSelector = state => state.stats;

const transactionsHistorySelector = createPathSelector(rootSelector, "transactionsHistory");
const transactionsHistoryByDateSelector = createPathSelector(transactionsHistorySelector, "byDate");
const transactionsHistoryAllDatesSelector = createPathSelector(transactionsHistorySelector, "allDates");

export const transactionsHistoryItemsSelector = createSelector(
  [transactionsHistoryByDateSelector, transactionsHistoryAllDatesSelector],
  (byDate, allDates) => allDates.map(date => byDate[date])
);

const priceHistorySelector = createPathSelector(rootSelector, "priceHistory");
const priceHistoryByDateSelector = createPathSelector(priceHistorySelector, "byDate");
const priceHistoryAllDatesSelector = createPathSelector(priceHistorySelector, "allDates");

export const priceHistoryItemsSelector = createSelector(
  [priceHistoryByDateSelector, priceHistoryAllDatesSelector],
  (byDate, allDates) => allDates.map(date => byDate[date])
);
