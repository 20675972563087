import { useState, useCallback } from "react";
import { useSelector } from "react-redux";
import useActions from "@utils/hooks/useActions";
import * as actions from "@graphs/actions";
import * as selectors from "@graphs/selectors";
import * as constants from "@constants";

export const useGraphSearch = () => {
  const walletVertices = useSelector(selectors.walletVerticesSelector);
  const transactionEdges = useSelector(selectors.transactionEdgesSelector);

  const { selectWallet } = useActions(actions);

  const [searchError, setSearchError] = useState(null);

  const performSearch = useCallback(
    (query) => {
      const matchingWalletVertex = walletVertices.find(
        (vertex) => vertex.address === query
      );
      if (matchingWalletVertex) {
        setSearchError(null);
        selectWallet({ address: matchingWalletVertex.address });
        return;
      }
      const matchingTransactionEdge = transactionEdges.find(
        (edge) => edge.hash === query
      );
      if (matchingTransactionEdge) {
        setSearchError(null);
        selectWallet({ address: matchingTransactionEdge.from });
        return;
      }
      setSearchError(
        query.length === constants.WALLET_HASH_LENGTH
          ? constants.SEARCH_ERROR.WALLET_NOT_FOUND
          : constants.SEARCH_ERROR.TRANSACTION_NOT_FOUND
      );
    },
    [walletVertices, transactionEdges, selectWallet]
  );

  return {
    performSearch,
    searchError,
  };
};
