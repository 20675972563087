import { createSelector } from "reselect";
import { createPathSelector } from "@utils/selectorUtils";
import * as constants from "@graphs/constants";

const rootSelector = (state) => state.graphs;

export const walletVerticesSelector = createPathSelector(
  rootSelector,
  "walletVertices"
);

export const transactionEdgesSelector = createPathSelector(
  rootSelector,
  "transactionEdges"
);

export const selectedEntityTypeSelector = createPathSelector(
  rootSelector,
  "selectedEntityType"
);

export const selectedEntityHashSelector = createPathSelector(
  rootSelector,
  "selectedEntityHash"
);

export const selectedWalletTransactionsSelector = createSelector(
  [
    selectedEntityTypeSelector,
    selectedEntityHashSelector,
    transactionEdgesSelector,
  ],
  (selectedEntityType, selectedEntityHash, transactionEdges) =>
    selectedEntityType !== constants.GRAPH_ENTITY_TYPES.WALLET
      ? null
      : transactionEdges.filter(
          ({ from, to }) =>
            selectedEntityHash === from || selectedEntityHash === to
        )
);

export const selectedWalletTransactionHashesSelector = createSelector(
  [selectedWalletTransactionsSelector],
  (selectedWalletTransactions) =>
    selectedWalletTransactions
      ? new Set(selectedWalletTransactions.map(({ hash }) => hash))
      : null
);

export const selectedWalletAdjacentWalletAddressesSelector = createSelector(
  [selectedEntityHashSelector, selectedWalletTransactionsSelector],
  (selectedEntityHash, selectedWalletTransactions) =>
    selectedWalletTransactions
      ? new Set(
          selectedWalletTransactions.map(({ from, to }) =>
            selectedEntityHash === from ? to : from
          )
        )
      : null
);

export const selectedTransactionWalletAddressesSelector = createSelector(
  [
    selectedEntityTypeSelector,
    selectedEntityHashSelector,
    transactionEdgesSelector,
  ],
  (selectedEntityType, selectedEntityHash, transactionEdges) => {
    if (selectedEntityType !== constants.GRAPH_ENTITY_TYPES.TRANSACTION)
      return null;
    const { from, to } = transactionEdges.find(
      (tx) => tx.hash === selectedEntityHash
    );
    return new Set([from, to]);
  }
);

const metaSelector = createPathSelector(rootSelector, "meta");

export const isFetchingSelector = createPathSelector(
  metaSelector,
  "isFetching"
);

export const fetchErrorSelector = createPathSelector(
  metaSelector,
  "fetchError"
);
