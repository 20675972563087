import styled from "styled-components";
import * as constants from "@constants";

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  height: 100%;
`;

export const ButtonsWrapper = styled.div`
  display: none;
  position: absolute;
  top: 50px;
  right: 0;
  color: white;
  z-index: 1;
`;

export const Button = styled.div`
  margin-left: 8px;
  cursor: pointer;
  color: ${({ active }) =>
    active ? constants.COLORS.GOLDEN : constants.COLORS.WHITE};
`;
