import styled from "styled-components";
import breakpoints from "@constants/breakpoints";
import * as constants from "@constants";

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 33px;
  user-select: none;

  & > *:not(:first-child) {
    margin-left: 10px;
  }

  @media (max-width: ${breakpoints.md}px) {
    & > *:not(:first-child) {
      margin-left: 2px;
    }
  }
`;

export const PageButton = styled.button`
  min-height: 25px;
  padding: 0 8px;

  background: ${({ active }) =>
    active ? constants.COLORS.GOLDEN : constants.COLORS.TRANSPARENT};
  color: ${({ active, highlighted, theme }) => {
    switch (true) {
      case theme === constants.THEMES.LIGHT && active:
        return constants.COLORS.WHITE;
      case theme === constants.THEMES.LIGHT:
        return constants.COLORS.BLACKEST_EVER_BLACK;
      case highlighted && !active:
        return constants.COLORS.QUARTER_OPAQUE_GOLDEN;
      default:
        return constants.COLORS.WHITE;
    }
  }};

  border: 0;
  border-radius: 3px;

  cursor: ${({ disabled }) => (disabled ? "default" : "pointer")};

  &:focus {
    outline: none;
  }
`;
