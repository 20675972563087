import React, { useState, useEffect } from "react";
import { useGraphSearch } from "@graphs/hooks/useGraphSearch";
import { Search } from "@common/components/Search";
import * as constants from "@constants";

export const GraphSearch = () => {
  const { performSearch, searchError: searchDataError } = useGraphSearch();
  const [searchQuery, setSearchQuery] = useState("");
  const [searchValidationError, setSearchValidationError] = useState(null);
  const [isSearchTouched, setIsSearchTouched] = useState(false);
  const searchError = searchDataError || searchValidationError;

  const handleSearchQueryChange = event => setSearchQuery(event.target.value);

  const handleSearchSubmit = query => {
    setIsSearchTouched(true);
    if (
      query.length === constants.WALLET_HASH_LENGTH ||
      query.length === constants.TRANSACTION_HASH_LENGTH
    ) {
      performSearch(query);
      setSearchValidationError(null);
    } else {
      setSearchValidationError(constants.SEARCH_ERROR.INVALID_FORMAT);
    }
  };

  useEffect(() => {
    if (isSearchTouched && !searchError) {
      setIsSearchTouched(false);
      setSearchQuery("");
    }
  }, [isSearchTouched, searchError]);

  return (
    <Search
      query={searchQuery}
      error={isSearchTouched ? searchError : null}
      onChange={handleSearchQueryChange}
      onSubmit={handleSearchSubmit}
    />
  );
};
