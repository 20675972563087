// React
import React from "react";
// Components
import HistoryChart from "@common/components/HistoryChart";
// Hooks
import usePriceHistory from "@stats/hooks/usePriceHistory";

export default function PriceHistoryChart() {
  return (
    <HistoryChart title="Price History" useHistoryData={usePriceHistory} lineDataKey="average" xAxisDataKey="day" />
  );
}
