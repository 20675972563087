import { handleActions } from "redux-actions";
import * as actions from "@blocks/actions";
import * as objectUtils from "@utils/objectUtils";
import { extractTransactionDetailsFromPayload } from "@transactions/reducers";

const initialState = {
  latestBlocks: {
    totalCount: 0,
    page: 0,
    limit: 0,
    byHash: {},
    allHashes: [],
    meta: {
      isFetching: false,
      fetchError: null
    }
  },
  blockDetails: {
    height: null,
    hash: null,
    timestamp: null,
    transactionsCount: null,
    amount: null,
    meta: {
      isFetching: false,
      fetchError: null
    }
  },
  blockTransactions: {
    totalCount: 0,
    page: 0,
    byHash: {},
    allHashes: [],
    meta: {
      isFetching: false,
      fetchError: null
    }
  }
};

export const blocksReducer = handleActions(
  {
    [actions.fetchLatestBlocks]: (state, { payload }) =>
      objectUtils.mergeDeepRight(state, {
        latestBlocks: {
          meta: {
            isFetching: true,
            fetchError: false
          }
        }
      }),
    [actions.fetchLatestBlocksSuccess]: (state, { payload }) =>
      objectUtils.mergeDeepRight(state, {
        latestBlocks: {
          totalCount: payload.totalCount,
          page: payload.page,
          limit: payload.limit,
          byHash: payload.items.reduce((result, payloadItem) => {
            const blockDetails = extractBlockDetailsFromPayload(payloadItem);
            return { ...result, [payloadItem.hash]: blockDetails };
          }, {}),
          allHashes: payload.items.map(payloadItem => payloadItem.hash),
          meta: {
            isFetching: false,
            fetchError: null
          }
        }
      }),
    [actions.fetchLatestBlocksSuccessFromCache]: (state, { payload }) =>
      objectUtils.mergeDeepRight(state, {
        latestBlocks: {
          limit: payload.limit,
          allHashes: payload.allHashes,
          meta: {
            isFetching: false,
            fetchError: null
          }
        }
      }),
    [actions.fetchLatestBlocksFailure]: (state, { payload }) =>
      objectUtils.mergeDeepRight(state, {
        latestBlocks: {
          meta: {
            isFetching: false,
            fetchError: payload
          }
        }
      }),
    [actions.fetchBlockDetails]: (state, { payload }) =>
      objectUtils.mergeDeepRight(state, {
        blockDetails: {
          meta: {
            isFetching: true
          }
        }
      }),
    [actions.fetchBlockDetailsSuccess]: (state, { payload }) =>
      objectUtils.mergeDeepRight(state, {
        blockDetails: {
          ...extractBlockDetailsFromPayload(payload),
          meta: {
            isFetching: false,
            fetchError: undefined
          }
        }
      }),
    [actions.fetchBlockDetailsFailure]: (state, { payload }) =>
      objectUtils.mergeDeepRight(state, {
        blockDetails: {
          meta: {
            isFetching: false,
            fetchError: payload
          }
        }
      }),
    // TODO: normalize data using "key windows" concept from
    // https://medium.com/@dcousineau/advanced-redux-entity-normalization-f5f1fe2aefc5
    [actions.fetchBlockTransactions]: (state, { payload }) =>
      objectUtils.mergeDeepRight(state, {
        blockTransactions: {
          meta: {
            isFetching: true,
            fetchError: false
          }
        }
      }),
    [actions.fetchBlockTransactionsSuccess]: (state, { payload }) =>
      objectUtils.mergeDeepRight(state, {
        blockTransactions: {
          totalCount: payload.totalCount,
          page: payload.page,
          byHash: payload.items.reduce((result, payloadItem) => {
            const details = extractTransactionDetailsFromPayload(payloadItem);
            return { ...result, [payloadItem.hash]: details };
          }, {}),
          allHashes: payload.items.map(payloadItem => payloadItem.hash),
          meta: {
            isFetching: false,
            fetchError: null
          }
        }
      }),
    [actions.fetchBlockTransactionsFailure]: (state, { payload }) =>
      objectUtils.mergeDeepRight(state, {
        blockTransactions: {
          meta: {
            isFetching: false,
            fetchError: payload
          }
        }
      })
  },
  initialState
);

export const extractBlockDetailsFromPayload = payload => ({
  height: payload.number,
  hash: payload.hash,
  timestamp: payload.timestamp,
  transactionsCount: payload.count,
  amount: payload.amount
});
