import React, { useState } from "react";
import * as constants from "@constants";
import * as Styles from "./styles";

export const DimensionsSwitch = ({ initialDimensions, twoDimensionalComponent, threeDimensionalComponent }) => {
  const [dimensions, setDimensions] = useState(initialDimensions);
  return (
    <Styles.Wrapper>
      <Styles.ButtonsWrapper>
        <Styles.Button
          active={dimensions === constants.DIMENSIONS.TWO}
          onClick={() => setDimensions(constants.DIMENSIONS.TWO)}
        >
          2d
        </Styles.Button>
        <Styles.Button
          active={dimensions === constants.DIMENSIONS.THREE}
          onClick={() => setDimensions(constants.DIMENSIONS.THREE)}
        >
          3d
        </Styles.Button>
      </Styles.ButtonsWrapper>
      {dimensions === constants.DIMENSIONS.TWO && twoDimensionalComponent()}
      {dimensions === constants.DIMENSIONS.THREE && threeDimensionalComponent()}
    </Styles.Wrapper>
  );
};
