import { useRef, useEffect } from "react";

export const INFINITE_LOOP_MODE = {
  INTERVAL_LOOP: "INTERVAL_LOOP",
  ANIMATION_FRAME_LOOP: "ANIMATION_FRAME_LOOP",
};

export const useInfiniteLoop = (mode, renderCallback) => {
  const renderCallbackRef = useRef(renderCallback);
  useEffect(() => {
    renderCallbackRef.current = renderCallback;
  }, [renderCallback]);
  useEffect(() => {
    switch (mode) {
      case INFINITE_LOOP_MODE.ANIMATION_FRAME_LOOP: {
        let animationFrameHandle;
        const wrapperCallback = () => {
          renderCallbackRef.current();
          animationFrameHandle = requestAnimationFrame(wrapperCallback);
        };
        animationFrameHandle = requestAnimationFrame(wrapperCallback);
        return () => {
          cancelAnimationFrame(animationFrameHandle);
        };
      }
      case INFINITE_LOOP_MODE.INTERVAL_LOOP: {
        const wrapperCallback = () => renderCallbackRef.current();
        const intervalHandle = setInterval(wrapperCallback, 0);
        return () => {
          clearInterval(intervalHandle);
        };
      }
      default:
        throw new Error(`Unknown loop mode: ${mode}`);
    }
  }, []);
};
