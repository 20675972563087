import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { ConnectedRouter } from "connected-react-router";
import { Route } from "react-router-dom";
import { QueryParamProvider } from "use-query-params";
import { App } from "@app/components/App";
import { store, history } from "@store";

function withPrevLocation(callback) {
  let prevLocation = undefined;
  return location => {
    callback(prevLocation, location);
    prevLocation = location;
  };
}

history.listen(
  withPrevLocation((prevLocation, location) => {
    if (prevLocation?.pathname === location.pathname) return;
    window.scrollTo(0, 0);
  })
);

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <QueryParamProvider ReactRouterRoute={Route}>
        <App />
      </QueryParamProvider>
    </ConnectedRouter>
  </Provider>,
  document.getElementById("root")
);
