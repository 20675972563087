// Libraries
import React from "react";
// Components
import LogoWithText from "@app/components/LogoWithText";
// Styles
import * as Styles from "./styles";
// Routes
import * as routes from "@routes";
// Icons
import twitter from "./icons/twitter.svg";
import facebook from "./icons/facebook.svg";
import youtube from "./icons/youtube.svg";
import telegram from "./icons/telegram.svg";

export default function Footer() {
  return (
    <Styles.Wrapper>
      <Styles.LinksSection>
        <LogoWithText />
        <Styles.EexLinks>
          <Styles.EexInternalLink to={routes.LATEST_TRANSACTIONS}>
            Transactions
          </Styles.EexInternalLink>
          <Styles.EexInternalLink to={routes.LATEST_BLOCKS}>
            Blocks
          </Styles.EexInternalLink>
          <Styles.EexInternalLink to={routes.STATS}>
            Stats
          </Styles.EexInternalLink>
          <Styles.EexExternalLink href="https://wallet.ethereum-express.com/">
            Wallet
          </Styles.EexExternalLink>
          <Styles.EexExternalLink href="https://cex.ethereum-express.com/">
            Exchange
          </Styles.EexExternalLink>
        </Styles.EexLinks>
        <Styles.SocialWrapper>
          <Styles.SocialText>Follow Us</Styles.SocialText>
          <Styles.SocialLinks>
            <Styles.SocialLink
              href={"https://twitter.com/ethereumexpress"}
              target="_blank"
              data-test="twitter-icon"
            >
              <img src={twitter} alt="twitter icon" />
            </Styles.SocialLink>
            <Styles.SocialLink
              href={"https://www.facebook.com/ethereumexpressfoundation"}
              target="_blank"
              data-test="fb-icon"
            >
              <img src={facebook} alt="facebook icon" />
            </Styles.SocialLink>
            <Styles.SocialLink
              href={"https://www.youtube.com/channel/UC8nJLBfVD4lrs0_Zc4AYLEg"}
              target="_blank"
              data-test="youtube-icon"
            >
              <img src={youtube} alt="youtube icon" />
            </Styles.SocialLink>
            <Styles.SocialLink
              href={"https://t.me/EthereumExpress"}
              target="_blank"
              data-test="telegram-icon"
            >
              <img src={telegram} alt="telegram-icon" />
            </Styles.SocialLink>
          </Styles.SocialLinks>
        </Styles.SocialWrapper>
      </Styles.LinksSection>
      <Styles.CopyrightSection>
        © 2020 Ethereum Express Foundation Limited. <Styles.MobileLineBreak />
        All rights reserved.
      </Styles.CopyrightSection>
    </Styles.Wrapper>
  );
}
