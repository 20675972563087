import styled from "styled-components";
import * as constants from "@constants";
import breakpoints from "@constants/breakpoints";

const ERROR_MARGIN = 5;
const ERROR_HEIGHT = 18;
const INPUT_HEIGHT = 40;

export const Wrapper = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  user-select: none;
`;

export const ControlsWrapper = styled.div`
  display: flex;

  @media (min-width: ${breakpoints.md}px) {
    & > *:not(:first-child) {
      margin-left: 37px;
    }
  }

  @media (max-width: ${breakpoints.md}px) {
    flex-direction: column;

    & > *:not(:first-child) {
      margin-top: ${ERROR_MARGIN * 2 + ERROR_HEIGHT + 2}px;
    }
  }
`;

export const InputErrorWrapper = styled.div`
  position: relative;
  flex: 1;
`;

export const ErrorWrapper = styled.div`
  position: absolute;
  top: ${INPUT_HEIGHT + ERROR_MARGIN}px;
  font-size: 13px;
  color: ${constants.COLORS.RED};
`;

export const Input = styled.input`
  width: 100%;
  min-height: ${INPUT_HEIGHT}px;

  border-left: 0;
  border-right: 0;
  border-top: 0;
  border-bottom: 1px solid #ffffff;
  border-radius: 0;
  box-sizing: border-box;

  font-family: Roboto;
  font-size: 15px;
  line-height: 18px;

  color: #9e9e9e;
  background: #000000;

  &:focus {
    outline: none;
  }

  @media (max-width: ${breakpoints.md}px) {
    width: 100%;
    font-size: 20px;
  }
`;

export const Button = styled.button`
  width: 114px;
  height: 40px;

  border: 1px solid #ffffff;
  box-sizing: border-box;

  font-family: Roboto;
  font-size: 15px;
  line-height: 18px;

  color: #ffffff;
  background: black;

  cursor: pointer;

  @media (max-width: ${breakpoints.md}px) {
    width: 100%;
    font-size: 20px;
  }
`;
