import React from "react";
import { SEARCH_ERROR } from "@constants";
import * as Styles from "./styles";

export const Search = ({ query, error, onChange, onSubmit }) => {
  const handleFormSubmit = (event) => {
    onSubmit(query.replace(/\s/g, ""));
    event.preventDefault();
  };

  const errorText = {
    [SEARCH_ERROR.INVALID_FORMAT]: "Invalid address format!",
    [SEARCH_ERROR.TRANSACTION_NOT_FOUND]: "Transaction not found!",
    [SEARCH_ERROR.WALLET_NOT_FOUND]: "Wallet not found!",
  }[error];

  return (
    <Styles.Wrapper onSubmit={handleFormSubmit}>
      <Styles.ControlsWrapper>
        <Styles.InputErrorWrapper>
          <Styles.Input
            type="text"
            placeholder="Address / Tx hash"
            value={query}
            onChange={onChange}
          />
          <Styles.ErrorWrapper>{errorText}</Styles.ErrorWrapper>
        </Styles.InputErrorWrapper>
        <Styles.Button type="submit" disabled={query.trim() === ""}>
          Search
        </Styles.Button>
      </Styles.ControlsWrapper>
    </Styles.Wrapper>
  );
};
