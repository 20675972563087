import { shade } from "polished";
import * as constants from "@constants";

export const ARC_STROKE_WIDTH_MOBILE = 0.003;
export const ARC_STROKE_WIDTH_DESKTOP = 0.001;

export const MIN_DOT_RADIUS_SVG_MOBILE = 0.03;
export const DOT_SIZE_SCALE_FACTOR_SVG_MOBILE = 0.075;

export const MIN_DOT_RADIUS_SVG_DESKTOP = 0.02;
export const DOT_SIZE_SCALE_FACTOR_SVG_DESKTOP = 0.05;

export const COLORS = {
  // wallet dots
  INACTIVE_DOT_COLOR: constants.COLORS.WHITE,
  FADED_INACTIVE_DOT_COLOR: constants.COLORS.DARKER_GRAY,
  SECONDARY_ACTIVE_DOT_COLOR: shade(0.3, constants.COLORS.GOLDEN),
  ACTIVE_DOT_LABEL_COLOR: constants.COLORS.GOLDEN,
  SECONDARY_ACTIVE_DOT_LABEL_COLOR: shade(0.3, constants.COLORS.GOLDEN),
  // transaction arcs
  INACTIVE_ARC_COLOR: shade(0.3, constants.COLORS.DARK_GRAY),
  ACTIVE_ARC_COLOR: constants.COLORS.GOLDEN,
  SECONDARY_ACTIVE_ARC_COLOR: shade(0.4, constants.COLORS.GOLDEN),
  ACTIVE_TRANSACTION_HASH_LABEL_COLOR: constants.COLORS.GOLDEN,
  // other
  TRANSACTION_COUNT_LABEL_COLOR: constants.COLORS.BLACKEST_EVER_BLACK,
};

export const Z_INDEXES = {
  INACTIVE_ARCS: 0,
  INACTIVE_DOTS: 1,
  ACTIVE_ARCS: 2,
  SECONDARY_ACTIVE_DOTS: 3,
  TRANSACTION_COUNT_LABELS: 4,
  SECONDARY_DOT_LABELS: 5,
  ACTIVE_DOT: 6,
  ACTIVE_DOT_LABEL: 7,
  ACTIVE_TRANSACTION_HASH_LABEL: 8,
};

export const LABEL_TYPES = {
  WALLET_ADDRESS_LABEL: "WALLET_ADDRESS_LABEL",
  TRANSACTION_COUNT_LABEL: "TRANSACTION_COUNT_LABEL",
  TRANSACTION_HASH_LABEL: "TRANSACTION_HASH_LABEL",
};

export const MAX_SECONDARY_LABELS_COUNT = 3;

export const DOT_LABEL_FONT_SIZE_MOBILE = 0.05;
export const DOT_LABEL_FONT_SIZE_DESKTOP = 0.03;

export const DOT_LABEL_OFFSET_Y_MOBILE = 0.03;
export const DOT_LABEL_OFFSET_Y_DESKTOP = 0.0225;

export const TRANSACTION_HASH_LABEL_FONT_SIZE_MOBILE = 0.05;
export const TRANSACTION_HASH_LABEL_FONT_SIZE_DESKTOP = 0.03;

export const TRANSACTION_COUNT_LABEL_FONT_SIZE_MOBILE = 0.05;
export const TRANSACTION_COUNT_LABEL_FONT_SIZE_DESKTOP = 0.03;
