import * as arrayUtils from "./arrayUtils";
import * as functionUtils from "./functionUtils";

export const removeTrailingCharacter = (char) =>
  functionUtils.pipe(
    (s) => s.split(""),
    arrayUtils.reverse,
    arrayUtils.dropWhile((c) => c === char),
    arrayUtils.reverse,
    (s) => s.join("")
  );

export const ellipsify = (string, prefixCharsCount, suffixCharsCount) =>
  `${string.slice(0, prefixCharsCount)}...${string.slice(
    string.length - suffixCharsCount
  )}`;
