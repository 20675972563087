import styled, { css } from "styled-components";
import breakpoints from "@constants/breakpoints";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 27px;

  cursor: pointer;

  @media (min-width: ${breakpoints.md}px) {
    display: ${({ active }) => (active ? "initial" : "none")};
  }

  ${({ active }) =>
    active &&
    css`
      position: fixed;
      top: 48px;
      right: 40px;
      z-index: 10;
    `};
`;

export const Ingredient = styled.span`
  position: relative;
  z-index: 1;

  display: block;
  width: 27px;
  height: 3px;
  margin-bottom: 5px;
  border-radius: 3px;

  background: #fff;

  transform-origin: 2px 0px;
  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
    background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease;

  &:first-child {
    ${({ active }) =>
      active &&
      css`
        opacity: 1;
        transform: rotate(45deg) translate(-2px, -1px);
      `};
  }

  &:nth-child(2) {
    ${({ active }) =>
      active &&
      css`
        opacity: 0;
        transform: rotate(0deg) scale(0.2, 0.2);
      `};
  }

  &:nth-child(3) {
    margin-bottom: 0;
    transform-origin: 0% 100%;

    ${({ active }) =>
      active &&
      css`
        transform: rotate(-45deg) translate(0, -1px);
      `};
  }
`;
