import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
  html, body, #root {
    height: 100%;
  }

  body {
    background: #FCFCFC;
    margin: 0;
    overflow-y: scroll;
  }

  a {
    color: #DB9E00;
    text-decoration: none;
  }

  :focus {
    outline: none;
  }

  ::-moz-focus-inner {
    border: 0;
  }

  body, button, input {
    font-family: Roboto;
    font-size: 15px;
    line-height: 18px;
  }
`;
