// React
import React from "react";
// Recharts
import { ResponsiveContainer, LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip } from "recharts";
// Styles
import * as constants from "@constants";

export default function Chart({ data, lineDataKey, xAxisDataKey, width, height }) {
  return (
    <ResponsiveContainer width={width ?? "100%"} height={height ?? 300}>
      <LineChart data={data} margin={{ top: 30, right: 40, bottom: 10, left: 0 }}>
        <Line type="monotone" dataKey={lineDataKey} stroke={constants.COLORS.ORANGE} />
        <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
        <XAxis dataKey={xAxisDataKey} tick={props => <CustomizedAxisTick {...props} />} />
        <YAxis tickMargin="4" tick={{ fontSize: 12 }} />
        <Tooltip />
      </LineChart>
    </ResponsiveContainer>
  );
}

// Workaround to fix https://github.com/recharts/recharts/issues/1664
const CustomizedAxisTick = ({ x, y, payload }) => (
  <g transform={`translate(${x},${y})`}>
    <text dy={18} fontSize={12} textAnchor="middle" fill="#666">
      {payload.value}
    </text>
  </g>
);
