import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 200px;
  min-height: 140px;
  font-size: 24px;
  font-weight: bold;
`;
