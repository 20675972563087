// React
import { useState, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
// Constants
import { GRAPH_ENTITY_TYPES } from "@graphs/constants";
// Actions
import * as actions from "@graphs/actions";
// Selectors
import * as selectors from "@graphs/selectors";
// Hooks
import useActions from "@utils/hooks/useActions";

export const useLatestGraphTransactions = ({ itemsPerPage, maxItemsCount }) => {
  const transactions = useSelector(selectors.transactionEdgesSelector);
  const isFetching = useSelector(selectors.isFetchingSelector);
  const fetchError = useSelector(selectors.fetchErrorSelector);

  const selectedEntityType = useSelector(selectors.selectedEntityTypeSelector);
  const selectedEntityHash = useSelector(selectors.selectedEntityHashSelector);
  const selectedWalletTransactionHashes = useSelector(
    selectors.selectedWalletTransactionHashesSelector
  );

  const totalCount = transactions.length;
  const maxPagesCount = Math.ceil(maxItemsCount / itemsPerPage);
  const pagesCount = Math.min(
    maxPagesCount,
    Math.ceil(totalCount / itemsPerPage)
  );

  const { fetchGraph } = useActions(actions);

  const [selectedPage, setSelectedPage] = useState(1);

  useEffect(() => {
    transactions.length === 0 && !isFetching && fetchGraph();
  }, []);

  const highlightedPageIndexes = useMemo(
    () =>
      new Set(
        transactions
          .map((transaction, index) => [transaction, index])
          .filter(([transaction, _]) =>
            selectedEntityType === GRAPH_ENTITY_TYPES.TRANSACTION
              ? selectedEntityHash === transaction.hash
              : selectedWalletTransactionHashes?.has(transaction.hash) ?? false
          )
          .map(([_, index]) => Math.floor(index / itemsPerPage) + 1)
      ),
    [
      transactions,
      selectedEntityType,
      selectedEntityHash,
      selectedWalletTransactionHashes,
      itemsPerPage,
    ]
  );

  useEffect(() => {
    if (transactions.length > 0 && highlightedPageIndexes.size > 0) {
      if (!highlightedPageIndexes.has(selectedPage)) {
        setSelectedPage(highlightedPageIndexes.values().next().value);
      }
    }
  }, [transactions, highlightedPageIndexes]);

  const displayTransactions = useMemo(() => {
    const offset = itemsPerPage * (selectedPage - 1);
    return transactions
      .slice(offset, offset + itemsPerPage)
      .map((transaction) => ({
        ...transaction,
        isSelected:
          selectedEntityType === GRAPH_ENTITY_TYPES.TRANSACTION
            ? selectedEntityHash === transaction.hash
            : selectedWalletTransactionHashes?.has(transaction.hash) ?? false,
      }));
  }, [
    transactions,
    selectedEntityType,
    selectedEntityHash,
    selectedWalletTransactionHashes,
    selectedPage,
    itemsPerPage,
  ]);

  return {
    transactions: displayTransactions,
    page: selectedPage,
    pagesCount,
    highlightedPageIndexes,
    isFetching,
    fetchError,
    selectPage: setSelectedPage,
  };
};
