export const FADE_IN_DURATION_MILLIS = 4000;
export const ANIMATION_DURATION_MILLIS = 100;

export const GRAPH_ENTITY_TYPES = {
  WALLET: "WALLET",
  TRANSACTION: "TRANSACTION",
};

export * from "./2d";
export * from "./3d";
