// React
import { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
// Actions
import * as actions from "@transactions/actions";
// Selectors
import * as selectors from "@transactions/selectors";
// Constants
import * as constants from "@constants";
// Hooks
import { useQueryParam, NumberParam } from "use-query-params";
import useActions from "@utils/hooks/useActions";

export default function useLatestTransactions(itemsPerPage) {
  const transactions = useSelector(selectors.latestTransactionsItemsSelector);
  const totalCount = useSelector(
    selectors.latestTransactionsTotalCountSelector
  );

  const page = useSelector(selectors.latestTransactionsPageSelector);
  const limit = useSelector(selectors.latestTransactionsLimitSelector);
  const pagesCount = Math.min(
    constants.MAX_PAGES_COUNT,
    Math.ceil(totalCount / itemsPerPage)
  );

  const isFetching = useSelector(
    selectors.latestTransactionsIsFetchingSelector
  );
  const fetchError = useSelector(
    selectors.latestTransactionsFetchErrorSelector
  );

  const { fetchLatestTransactions } = useActions(actions);

  const [queryPage, setQueryPage] = useQueryParam("page", NumberParam);
  const [selectedPage, setSelectedPage] = useState(queryPage || 1);

  const selectPage = useCallback(
    (page) => {
      setQueryPage(page);
      setSelectedPage(page);
    },
    [setQueryPage, setSelectedPage]
  );

  // sync page & selected page
  useEffect(() => {
    if (page !== selectedPage || limit !== itemsPerPage) {
      fetchLatestTransactions({ page: selectedPage, limit: itemsPerPage });
    }
  }, [page, selectedPage, limit, itemsPerPage, fetchLatestTransactions]);

  return {
    transactions,
    page: selectedPage,
    pagesCount,
    isFetching,
    fetchError,
    selectPage,
  };
}
