// Libraries
import React from "react";
// Components
import { Header } from "@app/components/Header";
import Footer from "@app/components/Footer";
// Styles
import * as Styles from "./styles";

export const GraphPagesLayout = ({ children }) => {
  return (
    <Styles.Wrapper>
      <Header />
      <Styles.ContentSection>{children}</Styles.ContentSection>
      <Footer />
    </Styles.Wrapper>
  );
};
