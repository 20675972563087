import { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import useActions from "@utils/hooks/useActions";
import * as actions from "@graphs/actions";
import * as selectors from "@graphs/selectors";
import * as arrayUtils from "@utils/arrayUtils";

export const useGlobalGraph = () => {
  const vertices = useSelector(selectors.walletVerticesSelector);
  const edges = useSelector(selectors.transactionEdgesSelector);
  const isFetching = useSelector(selectors.isFetchingSelector);
  const fetchError = useSelector(selectors.fetchErrorSelector);

  const { fetchGraph } = useActions(actions);

  useEffect(() => {
    vertices.length === 0 && edges.length === 0 && !isFetching && fetchGraph();
  }, []);

  const [edgeCounts, edgeHashes] = useMemo(() => {
    const edgeCounts = new Map();
    const edgeHashes = new Map();
    for (const edge of edges) {
      const forwardKey = `${edge.from}_${edge.to}`;
      const reverseKey = `${edge.to}_${edge.from}`;
      edgeCounts.set(forwardKey, (edgeCounts.get(forwardKey) ?? 0) + 1);
      edgeCounts.set(reverseKey, (edgeCounts.get(reverseKey) ?? 0) + 1);
      edgeHashes.set(
        forwardKey,
        (edgeHashes.get(forwardKey) ?? new Set()).add(edge.hash)
      );
      edgeHashes.set(
        reverseKey,
        (edgeHashes.get(reverseKey) ?? new Set()).add(edge.hash)
      );
    }
    return [edgeCounts, edgeHashes];
  }, [edges]);

  const sanitizedEdges = useMemo(
    () =>
      arrayUtils.removeDuplicates((edge) => `${edge.from}_${edge.to}`, edges),
    [edges]
  );

  return {
    vertices,
    edges: sanitizedEdges,
    edgeCounts,
    edgeHashes,
    isFetching,
    fetchError,
  };
};
