// @ts-check

import { createAction } from "redux-actions";

export const FETCH_WALLET_DETAILS = "FETCH_WALLET_DETAILS";
export const FETCH_WALLET_DETAILS_SUCCESS = "FETCH_WALLET_DETAILS_SUCCESS";
export const FETCH_WALLET_DETAILS_FAILURE = "FETCH_WALLET_DETAILS_FAILURE";

export const fetchWalletDetails = createAction(FETCH_WALLET_DETAILS);
export const fetchWalletDetailsSuccess = createAction(FETCH_WALLET_DETAILS_SUCCESS);
export const fetchWalletDetailsFailure = createAction(FETCH_WALLET_DETAILS_FAILURE);

export const FETCH_WALLET_TRANSACTIONS = "FETCH_WALLET_TRANSACTIONS";
export const FETCH_WALLET_TRANSACTIONS_SUCCESS = "FETCH_WALLET_TRANSACTIONS_SUCCESS";
export const FETCH_WALLET_TRANSACTIONS_FAILURE = "FETCH_WALLET_TRANSACTIONS_FAILURE";

export const fetchWalletTransactions = createAction(FETCH_WALLET_TRANSACTIONS);
export const fetchWalletTransactionsSuccess = createAction(FETCH_WALLET_TRANSACTIONS_SUCCESS);
export const fetchWalletTransactionsFailure = createAction(FETCH_WALLET_TRANSACTIONS_FAILURE);
