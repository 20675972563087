// React
import { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
// Selectors
import * as selectors from "@stats/selectors";
// Actions
import * as actions from "@stats/actions";
// Utils
import * as dateFns from "date-fns";
// Hooks
import useActions from "@utils/hooks/useActions";

export default function useTransactionsHistory(periodInDays) {
  const historyData = useSelector(selectors.transactionsHistoryItemsSelector);
  const { fetchTransactionsHistory } = useActions(actions);

  useEffect(() => {
    fetchTransactionsHistory({ limit: periodInDays });
  }, [fetchTransactionsHistory, periodInDays]);

  const formattedHistoryData = useMemo(
    () => historyData.map(item => ({ ...item, day: dateFns.format(new Date(item.date), "dd MMM") })),
    [historyData]
  );

  return {
    historyData: formattedHistoryData
  };
}
