import { createAction } from "redux-actions";

export const FETCH_GRAPH = "FETCH_GRAPH";
export const FETCH_GRAPH_SUCCESS = "FETCH_GRAPH_SUCCESS";
export const FETCH_GRAPH_FAILURE = "FETCH_GRAPH_FAILURE";

export const fetchGraph = createAction(FETCH_GRAPH);
export const fetchGraphSuccess = createAction(FETCH_GRAPH_SUCCESS);
export const fetchGraphFailure = createAction(FETCH_GRAPH_FAILURE);

export const SELECT_WALLET = "SELECT_WALLET";
export const EXPAND_WALLET = "EXPAND_WALLET";

export const selectWallet = createAction(SELECT_WALLET);
export const expandWallet = createAction(EXPAND_WALLET);

export const SELECT_TRANSACTION = "SELECT_TRANSACTION";
export const EXPAND_TRANSACTION = "EXPAND_TRANSACTION";

export const selectTransaction = createAction(SELECT_TRANSACTION);
export const expandTransaction = createAction(EXPAND_TRANSACTION);
