// @ts-check

import { createAction } from "redux-actions";

export const GLOBAL_SEARCH = "GLOBAL_SEARCH";
export const GLOBAL_SEARCH_SUCCESS = "GLOBAL_SEARCH_SUCCESS";
export const GLOBAL_SEARCH_FAILURE = "GLOBAL_SEARCH_FAILURE";

export const search = createAction(GLOBAL_SEARCH);
export const searchSuccess = createAction(GLOBAL_SEARCH_SUCCESS);
export const searchFailure = createAction(GLOBAL_SEARCH_FAILURE);
