// Libraries
import React, { useMemo, useCallback, useRef } from "react";
// Hooks
import { useGlobalGraph, useGraphSelections } from "@graphs/hooks";
import {
  useSpringy,
  useProgress,
  useScreenInfo,
  useInfiniteLoop,
  INFINITE_LOOP_MODE,
} from "@utils/hooks";
// Utils
import { bakeVertices } from "@graphs/utils";
import { lerp } from "@utils/geometryUtils";
import { ellipsify } from "@utils/stringUtils";
// Constants
import * as constants from "@graphs/constants";
// Assets
import activeWalletIcon from "@graphs/assets/activeWalletIcon.png";

export const GlobalTransactions2d = () => {
  const { isMobileScreen } = useScreenInfo();

  const calculateRadius = useMemo(
    () =>
      isMobileScreen
        ? (vertex) =>
            Math.max(
              constants.MIN_DOT_RADIUS_SVG_MOBILE,
              vertex.size * constants.DOT_SIZE_SCALE_FACTOR_SVG_MOBILE
            )
        : (vertex) =>
            Math.max(
              constants.MIN_DOT_RADIUS_SVG_DESKTOP,
              vertex.size * constants.DOT_SIZE_SCALE_FACTOR_SVG_DESKTOP
            ),
    [isMobileScreen]
  );

  const {
    vertices: backendVertices,
    edges: backendEdges,
    edgeCounts,
    edgeHashes,
  } = useGlobalGraph();

  const {
    selectedEntityHash,
    selectedWalletAdjacentWalletAddresses,
    selectedTransactionWalletAddresses,
    selectWallet,
    expandWallet,
  } = useGraphSelections();

  const springyNodes = useMemo(
    () => backendVertices.map((vertex) => vertex.address),
    [backendVertices]
  );

  const springyEdges = useMemo(
    () => backendEdges.map((edge) => [edge.from, edge.to]),
    [backendEdges]
  );

  const { animatedVertices, isFromCache } = useSpringy({
    nodes: springyNodes,
    edges: springyEdges,
    updateGeometryIntervalMillis: constants.ANIMATION_DURATION_MILLIS,
    stiffness: isMobileScreen ? 20 : 40,
    repulsion: isMobileScreen ? 1600 : 400,
    damping: isMobileScreen ? 0.65 : 0.5,
    shouldCache: true,
  });

  const bakedVerticesMap = useMemo(
    () =>
      new Map(
        bakeVertices(backendVertices, animatedVertices).map((vertex) => [
          vertex.address,
          vertex,
        ])
      ),
    [backendVertices, animatedVertices]
  );

  const svgRef = useRef();

  const getWalletFadeInProgress = useProgress(
    constants.FADE_IN_DURATION_MILLIS,
    [bakedVerticesMap.size === 0]
  );

  const getTransactionFadeInProgress = useProgress(
    constants.FADE_IN_DURATION_MILLIS * 1.5,
    [bakedVerticesMap.size === 0]
  );

  const getAnimationProgress = useProgress(
    constants.ANIMATION_DURATION_MILLIS,
    [bakedVerticesMap]
  );

  useInfiniteLoop(INFINITE_LOOP_MODE.INTERVAL_LOOP, () => {
    const walletFadeInProgress = isFromCache
      ? 1
      : Math.min(1, getWalletFadeInProgress());

    const circleOpacity =
      walletFadeInProgress < 0.1 ? 0 : (walletFadeInProgress - 0.1) * 1.11;

    const transactionFadeInProgress = isFromCache
      ? 1
      : Math.min(1, getTransactionFadeInProgress());

    const lineOpacity =
      transactionFadeInProgress < 0.5
        ? 0
        : (transactionFadeInProgress - 0.5) * 2;

    const animProgress = Math.min(1, getAnimationProgress());

    for (const element of svgRef.current.children) {
      switch (element.tagName) {
        case "circle": {
          element.setAttribute("opacity", circleOpacity);
          const address = element.getAttribute("data-address");
          const vertex = bakedVerticesMap.get(address);
          if (vertex) {
            const x = lerp(vertex.prevX, vertex.x, animProgress);
            const y = lerp(vertex.prevY, vertex.y, animProgress);
            element.setAttribute("cx", x);
            element.setAttribute("cy", y);
          }
          break;
        }
        case "line": {
          element.setAttribute("opacity", lineOpacity);
          const sourceAddress = element.getAttribute("data-from");
          const targetAddress = element.getAttribute("data-to");
          const sourceVertex = bakedVerticesMap.get(sourceAddress);
          const targetVertex = bakedVerticesMap.get(targetAddress);
          if (sourceVertex && targetVertex) {
            const x1 = lerp(sourceVertex.prevX, sourceVertex.x, animProgress);
            const y1 = lerp(sourceVertex.prevY, sourceVertex.y, animProgress);
            const x2 = lerp(targetVertex.prevX, targetVertex.x, animProgress);
            const y2 = lerp(targetVertex.prevY, targetVertex.y, animProgress);
            element.setAttribute("x1", x1);
            element.setAttribute("y1", y1);
            element.setAttribute("x2", x2);
            element.setAttribute("y2", y2);
          }
          break;
        }
        case "text": {
          const labelType = element.getAttribute("data-label-type");
          switch (labelType) {
            case constants.LABEL_TYPES.WALLET_ADDRESS_LABEL: {
              element.setAttribute("opacity", circleOpacity);
              const address = element.getAttribute("data-address");
              const vertex = bakedVerticesMap.get(address);
              if (vertex) {
                const x = lerp(vertex.prevX, vertex.x, animProgress);
                const y = lerp(vertex.prevY, vertex.y, animProgress);
                const offsetY =
                  calculateRadius(vertex) +
                  (isMobileScreen
                    ? constants.DOT_LABEL_OFFSET_Y_MOBILE
                    : constants.DOT_LABEL_OFFSET_Y_DESKTOP);
                element.setAttribute("x", x);
                element.setAttribute("y", y + offsetY);
              }
              break;
            }
            case constants.LABEL_TYPES.TRANSACTION_COUNT_LABEL: {
              element.setAttribute("opacity", circleOpacity * 0.75);
              const sourceAddress = element.getAttribute("data-from");
              const targetAddress = element.getAttribute("data-to");
              const sourceVertex = bakedVerticesMap.get(sourceAddress);
              const targetVertex = bakedVerticesMap.get(targetAddress);
              if (sourceVertex && targetVertex) {
                const vertex =
                  selectedEntityHash === sourceAddress
                    ? targetVertex
                    : sourceVertex;
                const x = lerp(vertex.prevX, vertex.x, animProgress);
                const y = lerp(vertex.prevY, vertex.y, animProgress);
                element.setAttribute("x", x);
                element.setAttribute("y", y);
              }
              break;
            }
            case constants.LABEL_TYPES.TRANSACTION_HASH_LABEL: {
              element.setAttribute("opacity", circleOpacity);
              const address1 = element.getAttribute("data-address1");
              const address2 = element.getAttribute("data-address2");
              const vertex1 = bakedVerticesMap.get(address1);
              const vertex2 = bakedVerticesMap.get(address2);
              if (vertex1 && vertex2) {
                const x1 = lerp(vertex1.prevX, vertex1.x, animProgress);
                const y1 = lerp(vertex1.prevY, vertex1.y, animProgress);
                const x2 = lerp(vertex2.prevX, vertex2.x, animProgress);
                const y2 = lerp(vertex2.prevY, vertex2.y, animProgress);
                const x = (x1 + x2) / 2;
                const y = (y1 + y2) / 2;
                element.setAttribute("x", x);
                element.setAttribute("y", y);
              }
              break;
            }
            default:
              throw new Error(`Unknown label type: ${labelType}`);
          }
          break;
        }
        default:
          break;
      }
    }
  });

  const handleRootClick = useCallback(() => {
    selectWallet({ address: null });
  }, [selectWallet]);

  const handleWalletClick = useCallback(
    (event, vertex) => {
      const address = vertex.address;
      address === selectedEntityHash
        ? expandWallet({ address })
        : selectWallet({ address });
      event.stopPropagation();
    },
    [selectedEntityHash, selectWallet, expandWallet]
  );

  const transactionArcs = useMemo(
    () =>
      bakedVerticesMap.size === 0
        ? []
        : backendEdges.map((edge) => {
            const key = `${edge.from}_${edge.to}`;
            const isActive = edgeHashes.get(key).has(selectedEntityHash);
            const isActiveSecondary =
              !isActive &&
              (selectedEntityHash === edge.from ||
                selectedEntityHash === edge.to);
            return (
              <line
                key={key}
                data-from={edge.from}
                data-to={edge.to}
                data-zindex={
                  isActive
                    ? constants.Z_INDEXES.ACTIVE_ARCS
                    : constants.Z_INDEXES.INACTIVE_ARCS
                }
                stroke={
                  (isActive && constants.COLORS.ACTIVE_ARC_COLOR) ||
                  (isActiveSecondary &&
                    constants.COLORS.SECONDARY_ACTIVE_ARC_COLOR) ||
                  constants.COLORS.INACTIVE_ARC_COLOR
                }
                strokeWidth={
                  isMobileScreen
                    ? constants.ARC_STROKE_WIDTH_MOBILE
                    : constants.ARC_STROKE_WIDTH_DESKTOP
                }
              />
            );
          }),
    [
      isMobileScreen,
      backendEdges,
      edgeHashes,
      bakedVerticesMap,
      selectedEntityHash,
    ]
  );

  const transactionCountLabels = useMemo(
    () =>
      edgeCounts.size === 0
        ? []
        : backendEdges
            .filter(
              (edge) =>
                selectedEntityHash === edge.from ||
                selectedEntityHash === edge.to
            )
            .map((edge) => {
              const key = `${edge.from}_${edge.to}`;
              const edgeCount = edgeCounts.get(key);
              const fontSize = isMobileScreen
                ? constants.TRANSACTION_COUNT_LABEL_FONT_SIZE_MOBILE
                : constants.TRANSACTION_COUNT_LABEL_FONT_SIZE_DESKTOP;
              return (
                <text
                  key={`text_${key}`}
                  data-label-type={
                    constants.LABEL_TYPES.TRANSACTION_COUNT_LABEL
                  }
                  data-from={edge.from}
                  data-to={edge.to}
                  data-zindex={constants.Z_INDEXES.TRANSACTION_COUNT_LABELS}
                  fontSize={fontSize}
                  dy={fontSize * 0.33}
                  style={{
                    fill: constants.COLORS.TRANSACTION_COUNT_LABEL_COLOR,
                    fontFamily: "Roboto",
                    textAnchor: "middle",
                    userSelect: "none",
                  }}
                >
                  {edgeCount > 99 ? "99" : edgeCount}
                </text>
              );
            }),
    [isMobileScreen, backendEdges, edgeCounts, selectedEntityHash]
  );

  const transactionHashLabels = useMemo(() => {
    if (
      !selectedTransactionWalletAddresses ||
      selectedTransactionWalletAddresses.size === 1
    ) {
      return [];
    }
    const [address1, address2] = Array.from(selectedTransactionWalletAddresses);
    return [
      <text
        key={"text_" + selectedEntityHash}
        data-label-type={constants.LABEL_TYPES.TRANSACTION_HASH_LABEL}
        data-address1={address1}
        data-address2={address2}
        data-zindex={constants.Z_INDEXES.ACTIVE_TRANSACTION_HASH_LABEL}
        fontSize={
          isMobileScreen
            ? constants.TRANSACTION_HASH_LABEL_FONT_SIZE_MOBILE
            : constants.TRANSACTION_HASH_LABEL_FONT_SIZE_DESKTOP
        }
        style={{
          fill: constants.COLORS.ACTIVE_TRANSACTION_HASH_LABEL_COLOR,
          fontFamily: "Roboto",
          fontWeight: "bold",
          textAnchor: "middle",
          dominantBaseline: "middle",
          textShadow: "black 0 0 4px",
          userSelect: "none",
        }}
      >
        {ellipsify(selectedEntityHash.slice(2), 4, 4)}
      </text>,
    ];
  }, [isMobileScreen, selectedTransactionWalletAddresses, selectedEntityHash]);

  const walletDots = useMemo(
    () =>
      Array.from(bakedVerticesMap.values(), (vertex) => {
        const isActive = vertex.address === selectedEntityHash;
        const isActiveSecondary =
          !isActive &&
          (selectedWalletAdjacentWalletAddresses?.has(vertex.address) ||
            selectedTransactionWalletAddresses?.has(vertex.address));
        return (
          <circle
            key={vertex.address}
            data-address={vertex.address}
            data-zindex={
              (isActive && constants.Z_INDEXES.ACTIVE_DOT) ||
              (isActiveSecondary &&
                constants.Z_INDEXES.SECONDARY_ACTIVE_DOTS) ||
              constants.Z_INDEXES.INACTIVE_DOTS
            }
            r={calculateRadius(vertex)}
            fill={
              (isActive && "url(#activeWallet)") ||
              (isActiveSecondary &&
                constants.COLORS.SECONDARY_ACTIVE_DOT_COLOR) ||
              (selectedEntityHash
                ? constants.COLORS.FADED_INACTIVE_DOT_COLOR
                : constants.COLORS.INACTIVE_DOT_COLOR)
            }
            onClick={(event) => handleWalletClick(event, vertex)}
            style={{ cursor: "pointer" }}
          />
        );
      }),
    [
      calculateRadius,
      bakedVerticesMap,
      selectedEntityHash,
      selectedWalletAdjacentWalletAddresses,
      selectedTransactionWalletAddresses,
      handleWalletClick,
    ]
  );

  const walletAddressLabels = useMemo(
    () =>
      Array.from(bakedVerticesMap.values())
        .filter(
          (vertex) =>
            vertex.address === selectedEntityHash ||
            selectedTransactionWalletAddresses?.has(vertex.address) ||
            (selectedWalletAdjacentWalletAddresses?.size <=
              constants.MAX_SECONDARY_LABELS_COUNT &&
              selectedWalletAdjacentWalletAddresses?.has(vertex.address))
        )
        .map((vertex) => (
          <text
            key={"text_" + vertex.address}
            data-label-type={constants.LABEL_TYPES.WALLET_ADDRESS_LABEL}
            data-address={vertex.address}
            data-zindex={
              vertex.address === selectedEntityHash
                ? constants.Z_INDEXES.ACTIVE_DOT_LABEL
                : constants.Z_INDEXES.SECONDARY_DOT_LABELS
            }
            fontSize={
              isMobileScreen
                ? constants.DOT_LABEL_FONT_SIZE_MOBILE
                : constants.DOT_LABEL_FONT_SIZE_DESKTOP
            }
            x={-1}
            y={-1}
            style={{
              fill:
                vertex.address === selectedEntityHash
                  ? constants.COLORS.ACTIVE_DOT_LABEL_COLOR
                  : constants.COLORS.SECONDARY_ACTIVE_DOT_LABEL_COLOR,
              fontFamily: "Roboto",
              fontWeight: "bold",
              textAnchor: "middle",
              dominantBaseline: "middle",
              textShadow:
                vertex.address === selectedEntityHash
                  ? "black 0 0 4px"
                  : "black 0 0 1px",
              userSelect: "none",
            }}
          >
            {ellipsify(vertex.address.slice(2), 4, 4)}
          </text>
        )),
    [
      isMobileScreen,
      bakedVerticesMap,
      selectedEntityHash,
      selectedWalletAdjacentWalletAddresses,
      selectedTransactionWalletAddresses,
    ]
  );

  const graphElements = useMemo(
    () =>
      [
        ...transactionArcs,
        ...transactionCountLabels,
        ...transactionHashLabels,
        ...walletDots,
        ...walletAddressLabels,
      ].sort(
        (a, b) => (a.props["data-zindex"] ?? 0) - (b.props["data-zindex"] ?? 0)
      ),
    [
      transactionArcs,
      transactionCountLabels,
      transactionHashLabels,
      walletDots,
      walletAddressLabels,
    ]
  );

  return (
    <svg
      ref={svgRef}
      width="100%"
      height="100%"
      viewBox="-0.25 -0.25 1.5 1.5"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={handleRootClick}
      {...(isMobileScreen && { transform: "scale(1.2)" })}
    >
      <defs>
        <pattern
          id="activeWallet"
          x="0%"
          y="0%"
          height="100%"
          width="100%"
          viewBox="0 0 106 106"
        >
          <image
            x="0%"
            y="0%"
            width="106"
            height="106"
            xlinkHref={activeWalletIcon}
          ></image>
        </pattern>
      </defs>
      {graphElements}
    </svg>
  );
};
