import { call, all, spawn } from "redux-saga/effects";
import * as searchSagas from "@search/sagas";
import * as transactionsSagas from "@transactions/sagas";
import * as blocksSagas from "@blocks/sagas";
import * as walletsSagas from "@wallets/sagas";
import * as statsSagas from "@stats/sagas";
import * as graphsSagas from "@graphs/sagas";

export default function* rootSaga() {
  const sagas = [
    searchSagas.watchSearch,
    searchSagas.redirectOnSearchSuccess,
    transactionsSagas.watchFetchLatestTransactions,
    transactionsSagas.watchFetchTransactionDetails,
    blocksSagas.watchFetchLatestBlocks,
    blocksSagas.watchFetchBlockDetails,
    blocksSagas.watchFetchBlockTransactions,
    walletsSagas.watchFetchWalletDetails,
    walletsSagas.watchFetchWalletTransactions,
    statsSagas.watchFetchTransactionsHistory,
    statsSagas.watchFetchPriceHistory,
    graphsSagas.watchFetchGraph,
    graphsSagas.watchExpandWallet,
    graphsSagas.watchExpandTransaction,
  ];
  yield all(
    sagas.map((saga) =>
      spawn(function* () {
        while (true) {
          try {
            yield call(saga);
            break;
          } catch (error) {
            console.log(`Unhandled error in saga '${saga.name}': `, error);
            console.log(`Restarting saga '${saga.name}'...`);
          }
        }
      })
    )
  );
}
