import styled from "styled-components";
import breakpoints from "@constants/breakpoints";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100%;
`;

export const Header = styled.header`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 99px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  background: #000000;
`;

export const SearchSection = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 218px;
  background: #000000;
`;

export const ContentSection = styled.section`
  width: 100%;
  padding: 0 200px;
  box-sizing: border-box;
  flex-grow: 1;
  margin-bottom: 50px;

  @media (max-width: ${breakpoints.xl}px) {
    padding: 0 50px;
  }

  @media (max-width: ${breakpoints.md}px) {
    padding: 0 20px;
  }
`;
