// React
import { useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
// Constants
import * as constants from "@constants";
// Actions
import * as actions from "@wallets/actions";
// Selectors
import * as selectors from "@wallets/selectors";
// Hooks
import { useQueryParam, NumberParam } from "use-query-params";
import useActions from "@utils/hooks/useActions";

export default function useWallet({ address: queryAddress }) {
  const address = useSelector(selectors.addressSelector);
  const balance = useSelector(selectors.balanceSelector);
  const isFetchingInfo = useSelector(selectors.isFetchingSelector);
  const fetchInfoError = useSelector(selectors.fetchErrorSelector);
  const transactions = useSelector(selectors.transactionsItemsSelector);
  const transactionsTotalCount = useSelector(
    selectors.transactionsTotalCountSelector
  );
  const transactionsPage = useSelector(selectors.transactionsPageSelector);
  const transactionsPagesCount = Math.ceil(
    transactionsTotalCount / constants.TRANSACTIONS_PER_PAGE
  );
  const isFetchingTransactions = useSelector(
    selectors.transactionsIsFetchingSelector
  );
  const fetchTransactionsError = useSelector(
    selectors.transactionsFetchErrorSelector
  );

  const { fetchWalletDetails, fetchWalletTransactions } = useActions(actions);

  const [queryPage = 1, setQueryPage] = useQueryParam("page", NumberParam);

  const selectTransactionsPage = useCallback(
    page => {
      setQueryPage(page);
    },
    [setQueryPage]
  );

  useEffect(() => {
    if (address !== queryAddress && !isFetchingInfo) {
      fetchWalletDetails({ address: queryAddress });
      fetchWalletTransactions({ address: queryAddress, page: queryPage });
    } else if (transactionsPage !== queryPage && !isFetchingTransactions) {
      fetchWalletTransactions({ address: queryAddress, page: queryPage });
    }
  }, [
    address,
    queryAddress,
    transactionsPage,
    queryPage,
    isFetchingInfo,
    fetchWalletDetails,
    isFetchingTransactions,
    fetchWalletTransactions
  ]);

  // jump to first page when out of range
  useEffect(() => {
    if (
      transactionsPagesCount &&
      (transactionsPage < 1 || transactionsPage > transactionsPagesCount)
    ) {
      selectTransactionsPage(1);
    }
  }, [transactionsPage, transactionsPagesCount, selectTransactionsPage]);

  return {
    address,
    balance,
    isFetchingInfo,
    fetchInfoError,
    transactions,
    transactionsPage: queryPage,
    transactionsPagesCount,
    isFetchingTransactions,
    fetchTransactionsError,
    selectTransactionsPage
  };
}
