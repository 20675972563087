// React
import { useSelector } from "react-redux";
// Selectors
import * as selectors from "@search/selectors";
// Actions
import * as actions from "@search/actions";
// Hooks
import useActions from "@utils/hooks/useActions";

export const useGlobalSearch = () => {
  const searchError = useSelector(selectors.searchErrorSelector);
  const isSearchPending = useSelector(selectors.isSearchPendingSelector);
  const { search: performSearch } = useActions(actions);
  return {
    searchError,
    isSearchPending,
    performSearch
  };
};
