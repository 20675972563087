// React
import { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
// Actions
import * as actions from "@blocks/actions";
// Selectors
import * as selectors from "@blocks/selectors";
// Constants
import * as constants from "@constants";
// Hooks
import { useQueryParam, NumberParam } from "use-query-params";
import useActions from "@utils/hooks/useActions";

export const useLatestBlocks = (itemsPerPage) => {
  const blocks = useSelector(selectors.latestBlocksItemsSelector);
  const totalCount = useSelector(selectors.latestBlocksTotalCountSelector);

  const page = useSelector(selectors.latestBlocksPageSelector);
  const limit = useSelector(selectors.latestBlocksLimitSelector);
  const pagesCount = Math.min(
    constants.MAX_PAGES_COUNT,
    Math.ceil(totalCount / itemsPerPage)
  );

  const isFetching = useSelector(selectors.latestBlocksIsFetchingSelector);
  const fetchError = useSelector(selectors.latestBlocksFetchErrorSelector);

  const { fetchLatestBlocks } = useActions(actions);

  const [queryPage, setQueryPage] = useQueryParam("page", NumberParam);
  const [selectedPage, setSelectedPage] = useState(queryPage || 1);

  const selectPage = useCallback(
    (page) => {
      setQueryPage(page);
      setSelectedPage(page);
    },
    [setQueryPage, setSelectedPage]
  );

  // sync page & selected page
  useEffect(() => {
    if (page !== selectedPage || limit !== itemsPerPage) {
      fetchLatestBlocks({ page: selectedPage, limit: itemsPerPage });
    }
  }, [page, selectedPage, limit, itemsPerPage, fetchLatestBlocks]);

  return {
    blocks,
    page: selectedPage,
    pagesCount,
    isFetching,
    fetchError,
    selectPage,
  };
};
