import { handleActions } from "redux-actions";
import * as actions from "@wallets/actions";
import * as objectUtils from "@utils/objectUtils";

const initialState = {
  info: {
    address: null,
    balance: 0,
    meta: {
      isFetching: false,
      fetchError: null
    }
  },
  transactions: {
    totalCount: 0,
    page: 0,
    items: [],
    meta: {
      isFetching: false,
      fetchError: null
    }
  }
};

export const walletsReducer = handleActions(
  {
    [actions.fetchWalletDetails]: (state, { payload }) =>
      objectUtils.mergeDeepRight(state, {
        info: {
          meta: {
            isFetching: true,
            fetchError: null
          }
        }
      }),
    [actions.fetchWalletDetailsSuccess]: (state, { payload }) =>
      objectUtils.mergeDeepRight(state, {
        info: {
          address: payload.address,
          balance: payload.balance,
          meta: {
            isFetching: false,
            fetchError: null
          }
        }
      }),
    [actions.fetchWalletDetailsFailure]: (state, { payload }) =>
      objectUtils.mergeDeepRight(state, {
        info: {
          meta: {
            isFetching: false,
            fetchError: payload
          }
        }
      }),
    [actions.fetchWalletTransactions]: (state, { payload }) =>
      objectUtils.mergeDeepRight(state, {
        transactions: {
          meta: {
            isFetching: true,
            fetchError: null
          }
        }
      }),
    [actions.fetchWalletTransactionsSuccess]: (state, { payload }) =>
      objectUtils.mergeDeepRight(state, {
        transactions: {
          totalCount: payload.totalCount,
          page: payload.page,
          items: payload.items,
          meta: {
            isFetching: false,
            fetchError: null
          }
        }
      }),
    [actions.fetchWalletTransactionsFailure]: (state, { payload }) =>
      objectUtils.mergeDeepRight(state, {
        transactions: {
          meta: {
            isFetching: false,
            fetchError: payload
          }
        }
      })
  },
  initialState
);
