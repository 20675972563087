// @ts-check

import { createPathSelector } from "@utils/selectorUtils";

const rootSelector = state => state.search;

export const searchErrorSelector = createPathSelector(
  rootSelector,
  "searchError"
);

export const isSearchPendingSelector = createPathSelector(
  rootSelector,
  "isSearchPending"
);
