// @ts-check

export const TRANSACTIONS_PER_PAGE = 10;

export const BLOCKS_PER_PAGE = 10;

export const DASHBOARD_TABLES_ITEMS_PER_PAGE = 5;

export const MAX_GRAPH_TRANSACTIONS_ITEMS_COUNT = 250;

export const MAX_PAGES_COUNT = 250;
