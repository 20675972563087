import { put, takeLatest } from "redux-saga/effects";
import * as explorerApi from "@api/explorerApi";
import * as actions from "@graphs/actions";

export function* fetchGraph() {
  const { error, result } = yield* explorerApi.getGraphData();
  if (error) {
    yield put(actions.fetchGraphFailure(error));
  } else {
    yield put(actions.fetchGraphSuccess(result));
  }
}

export function* watchFetchGraph() {
  yield takeLatest(actions.FETCH_GRAPH, fetchGraph);
}
