export const pipe = (...fns) =>
  fns.reduce(
    (f, g) => x => g(f(x)),
    x => x
  );

export const repeat = (repeatsCount, fn) => {
  for (let index = 0; index < repeatsCount; index++) {
    fn();
  }
};
