import styled from "styled-components";

export const Wrapper = styled.header`
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  width: 100%;
  padding: 30px 40px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);

  background: #000000;

  user-select: none;
`;
