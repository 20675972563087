import { put, takeLatest, select } from "redux-saga/effects";
import * as explorerApi from "@api/explorerApi";
import * as selectors from "@blocks/selectors";
import * as actions from "@blocks/actions";
import * as constants from "@constants";

export function* fetchLatestBlocks({ payload: { page, limit } }) {
  const currentPage = yield select(selectors.latestBlocksPageSelector);
  const currentLimit = yield select(selectors.latestBlocksLimitSelector);
  if (page === currentPage && limit < currentLimit) {
    const currentHashes = yield select(selectors.latestBlocksAllHashesSelector);
    return yield put(
      actions.fetchLatestBlocksSuccessFromCache({
        allHashes: currentHashes.slice(0, limit),
        limit
      })
    );
  }
  const { error, result } = yield* explorerApi.getLatestBlocks({
    skip: limit * (page - 1),
    limit: limit
  });
  if (error) {
    yield put(actions.fetchLatestBlocksFailure(error));
  } else {
    yield put(
      actions.fetchLatestBlocksSuccess({
        ...result,
        page,
        limit
      })
    );
  }
}

export function* watchFetchLatestBlocks() {
  yield takeLatest(actions.FETCH_LATEST_BLOCKS, fetchLatestBlocks);
}

export function* fetchBlockDetails({ payload: { hash } }) {
  const { error, result } = yield* explorerApi.getBlockDetails(hash);
  if (error) {
    yield put(actions.fetchBlockDetailsFailure(error));
  } else {
    yield put(actions.fetchBlockDetailsSuccess(result));
  }
}

export function* watchFetchBlockDetails() {
  yield takeLatest(actions.FETCH_BLOCK_DETAILS, fetchBlockDetails);
}

export function* fetchBlockTransactions({ payload: { hash, page } }) {
  const { error, result } = yield* explorerApi.getBlockTransactions(hash, {
    skip: constants.BLOCKS_PER_PAGE * (page - 1),
    limit: constants.BLOCKS_PER_PAGE
  });
  if (error) {
    yield put(actions.fetchBlockTransactionsFailure(error));
  } else {
    yield put(
      actions.fetchBlockTransactionsSuccess({
        ...result,
        page
      })
    );
  }
}

export function* watchFetchBlockTransactions() {
  yield takeLatest(actions.FETCH_BLOCK_TRANSACTIONS, fetchBlockTransactions);
}
