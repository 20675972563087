import styled from "styled-components";
import breakpoints from "@constants/breakpoints";
import * as constants from "@constants";

export const Card = {
  Wrapper: styled.div`
    background: ${constants.COLORS.WHITE};
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    padding: ${({ padding }) => padding ?? "0 35px 0 35px"};

    @media (max-width: ${breakpoints.sm}px) {
      padding: ${({ padding }) => padding ?? "0 12px 0 12px"};
    }
  `,
  Row: styled.div`
    display: flex;
    align-items: center;
    height: 68px;

    &:not(:last-child) {
      border-bottom: 1px solid #e5e5e5;
    }

    & > *:first-child {
      min-width: 179px;
      color: #8e8e8e;
    }

    & > *:last-child {
      overflow: hidden;
      color: #1d1d1d;
    }

    @media (max-width: ${breakpoints.sm}px) {
      & > *:first-child {
        min-width: 50%;
      }
    }
  `,
};
