// React
import React from "react";
import { Link, useParams } from "react-router-dom";
// Utils
import * as currencyUtils from "@utils/currencyUtils";
import * as dateFns from "date-fns";
// Routes
import * as routes from "@routes";
// Hooks
import useTransactionDetails from "@transactions/hooks/useTransactionDetails";
// Constants
import * as constants from "@constants";
// Styles
import EntityNotFound from "@common/components/EntityNotFound";
import Loader from "@common/components/Loader";
import * as CommonStyles from "@common/styles";

export default function TransactionDetails() {
  const { address: queryAddress } = useParams();

  const {
    address,
    sender,
    recipient,
    amount,
    fee,
    timestamp,
    blockNumber,
    isFetching,
    fetchError,
  } = useTransactionDetails({ address: queryAddress });

  if (isFetching) {
    return <Loader theme={constants.THEMES.LIGHT} />;
  }
  if (fetchError) {
    return <EntityNotFound name="Transaction" />;
  }
  return (
    <>
      <CommonStyles.Header>Transaction</CommonStyles.Header>
      <CommonStyles.Card.Wrapper>
        <CommonStyles.Card.Row>
          <div>Hash</div>
          <CommonStyles.Ellipsis>{address}</CommonStyles.Ellipsis>
        </CommonStyles.Card.Row>
        <CommonStyles.Card.Row>
          <div>Sender</div>
          <div>
            <Link to={`${routes.WALLET}/${sender}`}>
              <CommonStyles.Ellipsis>{sender}</CommonStyles.Ellipsis>
            </Link>
          </div>
        </CommonStyles.Card.Row>
        <CommonStyles.Card.Row>
          <div>Recipient</div>
          <div>
            <Link to={`${routes.WALLET}/${recipient}`}>
              <CommonStyles.Ellipsis>{recipient}</CommonStyles.Ellipsis>
            </Link>
          </div>
        </CommonStyles.Card.Row>
        <CommonStyles.Card.Row>
          <div>Amount</div>
          <CommonStyles.CurrencyWrapper>
            <CommonStyles.Ellipsis>
              {currencyUtils.prettifyCurrencyString(amount)}
            </CommonStyles.Ellipsis>{" "}
            EEX
          </CommonStyles.CurrencyWrapper>
        </CommonStyles.Card.Row>
        <CommonStyles.Card.Row>
          <div>Fee</div>
          <CommonStyles.CurrencyWrapper>
            <CommonStyles.Ellipsis>
              {currencyUtils.prettifyCurrencyString(fee)}
            </CommonStyles.Ellipsis>{" "}
            EEX
          </CommonStyles.CurrencyWrapper>
        </CommonStyles.Card.Row>
        <CommonStyles.Card.Row>
          <div>Time</div>
          <div>
            {timestamp &&
              dateFns.format(new Date(timestamp), "yyyy-MM-dd HH:mm:ss")}
          </div>
        </CommonStyles.Card.Row>
        <CommonStyles.Card.Row>
          <div>Block Number</div>
          <div>{blockNumber}</div>
        </CommonStyles.Card.Row>
      </CommonStyles.Card.Wrapper>
    </>
  );
}
