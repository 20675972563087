import styled from "styled-components";

export const HeaderRow = styled.div`
  display: flex;
  margin: ${({ margin }) => margin ?? "0"};

  & > *:nth-child(1) {
    flex-grow: 1;
  }
`;
