import React from "react";
import { Link } from "react-router-dom";
import Logo from "@common/components/Logo";
import * as routes from "@routes";
import * as Styles from "./styles";

export default function LogoWithText() {
  return (
    <Link to={routes.INDEX}>
      <Styles.Wrapper>
        <Logo />
        <Styles.Text>
          ETHEREUM
          <br />
          EXPRESS
          <br />
          COIN
        </Styles.Text>
      </Styles.Wrapper>
    </Link>
  );
}
