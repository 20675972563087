// React
import { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
// Constants
import * as constants from "@constants";
// Actions
import * as blocksActions from "@blocks/actions";
// Selectors
import * as blocksSelectors from "@blocks/selectors";
// Hooks
import { useQueryParam, NumberParam } from "use-query-params";
import useActions from "@utils/hooks/useActions";

export const useBlockDetails = ({ hash: queryHash }) => {
  const height = useSelector(blocksSelectors.blockDetailsHeightSelector);
  const hash = useSelector(blocksSelectors.blockDetailsHashSelector);
  const timestamp = useSelector(blocksSelectors.blockDetailsTimestampSelector);
  const transactionsCount = useSelector(
    blocksSelectors.blockDetailsTransactionsCountSelector
  );
  const amount = useSelector(blocksSelectors.blockDetailsAmountSelector);
  const isFetching = useSelector(
    blocksSelectors.blockDetailsIsFetchingSelector
  );
  const fetchError = useSelector(
    blocksSelectors.blockDetailsFetchErrorSelector
  );
  const transactions = useSelector(
    blocksSelectors.blockTransactionsItemsSelector
  );
  const transactionsTotalCount = useSelector(
    blocksSelectors.blockTransactionsTotalCountSelector
  );
  const transactionsPage = useSelector(
    blocksSelectors.blockTransactionsPageSelector
  );
  const transactionsPagesCount = Math.ceil(
    transactionsTotalCount / constants.TRANSACTIONS_PER_PAGE
  );
  const isFetchingTransactions = useSelector(
    blocksSelectors.blockTransactionsIsFetchingSelector
  );
  const fetchTransactionsError = useSelector(
    blocksSelectors.blockTransactionsFetchErrorSelector
  );

  const { fetchBlockDetails, fetchBlockTransactions } = useActions(
    blocksActions
  );

  const [queryPage, setQueryPage] = useQueryParam("page", NumberParam);

  const selectTransactionsPage = useCallback(
    page => {
      setQueryPage(page);
    },
    [setQueryPage]
  );

  useEffect(() => {
    if (hash !== queryHash && !isFetching) {
      fetchBlockDetails({ hash: queryHash });
      fetchBlockTransactions({ hash: queryHash, page: queryPage });
    } else if (transactionsPage !== queryPage && !isFetchingTransactions) {
      fetchBlockTransactions({ hash: queryHash, page: queryPage });
    }
  }, [
    hash,
    queryHash,
    transactionsPage,
    queryPage,
    isFetching,
    fetchBlockDetails,
    isFetchingTransactions,
    fetchBlockTransactions
  ]);

  // jump to first page when out of range
  useEffect(() => {
    if (
      transactionsPagesCount &&
      (transactionsPage < 1 || transactionsPage > transactionsPagesCount)
    ) {
      selectTransactionsPage(1);
    }
  }, [transactionsPage, transactionsPagesCount, selectTransactionsPage]);

  return {
    height,
    hash,
    timestamp,
    transactionsCount,
    amount,
    isFetching,
    fetchError,
    transactions,
    transactionsPage: queryPage,
    transactionsPagesCount,
    isFetchingTransactions,
    fetchTransactionsError,
    selectTransactionsPage
  };
};
