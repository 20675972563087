import React from "react";
import * as constants from "@constants";

export const SearchToggle = ({ isActive, onClick }) => {
  return (
    <svg
      width="13"
      height="13"
      viewBox="0 0 13 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ cursor: "pointer", minWidth: "19px", minHeight: "19px" }}
      onClick={onClick}
    >
      <path
        d="M12.8023 11.8409L9.59762 8.623C10.4216 7.67732 10.8731 6.48745 10.8731 5.24874C10.8731 2.35463 8.4342 0 5.43654 0C2.43888 0 0 2.35463 0 5.24874C0 8.14286 2.43888 10.4975 5.43654 10.4975C6.5619 10.4975 7.63432 10.1698 8.5512 9.5477L11.7803 12.7901C11.9152 12.9254 12.0968 13 12.2913 13C12.4754 13 12.6501 12.9322 12.7827 12.809C13.0645 12.5472 13.0735 12.1132 12.8023 11.8409ZM5.43654 1.36924C7.65229 1.36924 9.45485 3.10954 9.45485 5.24874C9.45485 7.38795 7.65229 9.12825 5.43654 9.12825C3.2208 9.12825 1.41823 7.38795 1.41823 5.24874C1.41823 3.10954 3.2208 1.36924 5.43654 1.36924Z"
        fill={isActive ? constants.COLORS.GOLDEN : constants.COLORS.WHITE}
      />
    </svg>
  );
};
