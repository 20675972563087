import React, { useState, useMemo, useEffect, useCallback } from "react";
import * as Styles from "./styles";

export default function Dropdown({ variants, value, onChange }) {
  const [isExpanded, setIsExpanded] = useState(false);
  const handleExternalClick = useCallback(() => {
    setIsExpanded(false);
  }, [setIsExpanded]);
  const handleDropdownClick = event => {
    setIsExpanded(isExpanded => !isExpanded);
  };
  const handleVariantClick = (event, variant) => {
    onChange(variant);
    setIsExpanded(false);
  };
  useEffect(() => {
    window.addEventListener("click", handleExternalClick);
    return () => {
      window.removeEventListener("click", handleExternalClick);
    };
  }, [handleExternalClick]);
  const title = useMemo(() => variants.find(variant => variant.value === value).title, [variants, value]);
  return (
    <Styles.OuterWrapper onClick={e => e.stopPropagation()}>
      <Styles.ActiveRowWrapper isExpanded={isExpanded} onClick={handleDropdownClick}>
        {title}
        <Styles.TriangleIconWrapper>
          <Styles.TriangleIcon isExpanded={isExpanded} />
        </Styles.TriangleIconWrapper>
      </Styles.ActiveRowWrapper>
      {isExpanded && (
        <Styles.ExpandedWrapper>
          {variants
            .filter(variant => variant.value !== value)
            .map((variant, index) => (
              <Styles.Variant key={index} onClick={event => handleVariantClick(event, variant.value)}>
                {variant.title}
              </Styles.Variant>
            ))}
        </Styles.ExpandedWrapper>
      )}
    </Styles.OuterWrapper>
  );
}
