// Libraries
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
// Commons
import * as selectors from "@app/selectors";
import * as routes from "@routes";
// Hooks
import { useGlobalSearch } from "@search/hooks/useGlobalSearch";
// Components
import { Search } from "@common/components/Search";
// Styles
import * as Styles from "./styles";

export const MobileNavigation = ({ active, onClose }) => {
  const currentRoute = useSelector(selectors.pathnameSelector);
  const { searchError, isSearchPending, performSearch } = useGlobalSearch();

  const [searchQuery, setSearchQuery] = useState("");
  const [isSearchTouched, setIsSearchTouched] = useState(false);

  const handleSearchQueryChange = (event) => setSearchQuery(event.target.value);

  const handleSearchSubmit = (query) => {
    performSearch({ query });
    setIsSearchTouched(true);
  };

  useEffect(() => {
    if (isSearchTouched && !isSearchPending && !searchError) {
      setIsSearchTouched(false);
      onClose();
    }
  }, [isSearchTouched, searchError, isSearchPending, onClose]);

  const handleClick = (event) => event.target.tagName === "A" && onClose();

  return (
    <Styles.Wrapper active={active} onClick={handleClick}>
      <Styles.SearchWrapper>
        <Search
          query={searchQuery}
          error={isSearchTouched && !isSearchPending ? searchError : null}
          onChange={handleSearchQueryChange}
          onSubmit={handleSearchSubmit}
        />
      </Styles.SearchWrapper>
      <Styles.EexInternalLink
        isActive={currentRoute === routes.INDEX}
        to={routes.INDEX}
      >
        Graph
      </Styles.EexInternalLink>
      <Styles.EexInternalLink
        isActive={currentRoute === routes.LATEST_TRANSACTIONS}
        to={routes.LATEST_TRANSACTIONS}
      >
        Transactions
      </Styles.EexInternalLink>
      <Styles.EexInternalLink
        isActive={currentRoute === routes.LATEST_BLOCKS}
        to={routes.LATEST_BLOCKS}
      >
        Blocks
      </Styles.EexInternalLink>
      <Styles.EexInternalLink
        isActive={currentRoute === routes.STATS}
        to={routes.STATS}
      >
        Stats
      </Styles.EexInternalLink>
      <Styles.Line />
      <Styles.EexExternalLink
        href="https://wallet.ethereum-express.com/"
        target="_blank"
        hideOnMobile={true}
      >
        Wallet
      </Styles.EexExternalLink>
      <Styles.EexExternalLink
        href="https://cex.ethereum-express.com/"
        target="_blank"
        hideOnMobile={true}
      >
        Exchange
      </Styles.EexExternalLink>
    </Styles.Wrapper>
  );
};
