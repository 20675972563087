import styled from "styled-components";
import breakpoints from "@constants/breakpoints";
import * as constants from "@constants";

export const Header = styled.header`
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
  margin: ${({ margin }) => margin ?? "50px 0 22px 0"};
  color: ${({ theme }) =>
    ({
      [constants.THEMES.LIGHT]: constants.COLORS.BLACK,
      [constants.THEMES.DARK]: constants.COLORS.WHITE,
    }[theme])};

  @media (max-width: ${breakpoints.md}px) {
    font-size: 20px;
    margin: ${({ margin }) => margin ?? "30px 0 22px 0"};
  }
`;
