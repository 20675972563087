// Libraries
import React, { useState } from "react";
// Components
import LogoWithText from "@app/components/LogoWithText";
import { Navigation } from "@app/components/Navigation";
import { MobileNavigation } from "@app/components/MobileNavigation";
import { Hamburger } from "@app/components/Hamburger";
// Styles
import * as Styles from "./styles";

export const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenuOpen = () => {
    const newIsMenuOpen = !isMenuOpen;
    document.documentElement.style.overflow = newIsMenuOpen ? "hidden" : "auto";
    document.body.style.overflow = newIsMenuOpen ? "hidden" : "scroll";
    setIsMenuOpen(newIsMenuOpen);
  };

  return (
    <Styles.Wrapper>
      <LogoWithText />
      {isMenuOpen && (
        <MobileNavigation active={isMenuOpen} onClose={toggleMenuOpen} />
      )}
      <Navigation />
      <Hamburger active={isMenuOpen} onClick={toggleMenuOpen} />
    </Styles.Wrapper>
  );
};
