import { useEffect, useRef, useCallback } from "react";

export const useProgress = (durationMillis, dependencies) => {
  const startTimestampRef = useRef(Date.now());
  useEffect(() => {
    startTimestampRef.current = Date.now();
  }, dependencies);
  const getProgress = useCallback(
    () => (Date.now() - startTimestampRef.current) / durationMillis,
    []
  );
  return getProgress;
};
