// React
import React from "react";
import { Link } from "react-router-dom";
// Utils
import * as dateFns from "date-fns";
import * as currencyUtils from "@utils/currencyUtils";
// Routes
import * as routes from "@routes";
// Hooks
import useLatestTransactions from "../../hooks/useLatestTransactions";
// Components
import Table from "@common/components/Table";
import EntityNotFound from "@common/components/EntityNotFound";
import Loader from "@common/components/Loader";
import Paginator from "@common/components/Paginator";
// Styles
import * as TableStyles from "@common/components/Table/styles";
import * as CommonStyles from "@common/styles";
import * as constants from "@constants";

export default function LatestTransactions({ itemsPerPage, hidePagination }) {
  const {
    transactions,
    page,
    pagesCount,
    isFetching,
    fetchError,
    selectPage,
  } = useLatestTransactions(itemsPerPage);
  if (fetchError) {
    return <EntityNotFound name="Transactions" />;
  }
  return (
    <div>
      {(isFetching || transactions.length > 0) && (
        <>
          <CommonStyles.Header theme={constants.THEMES.LIGHT}>
            Latest Transactions
          </CommonStyles.Header>
          <CommonStyles.Card.Wrapper>
            {isFetching ? (
              <Loader
                theme={constants.THEMES.LIGHT}
                minHeight={`${
                  (1 + itemsPerPage) * TableStyles.ROW_HEIGHT_PX
                }px`}
              />
            ) : (
              <Table
                theme={constants.THEMES.LIGHT}
                columns={TABLE_COLUMNS}
                items={transactions}
              />
            )}
          </CommonStyles.Card.Wrapper>
        </>
      )}
      {!hidePagination && pagesCount > 1 && (
        <Paginator
          theme={constants.THEMES.LIGHT}
          pagesCount={pagesCount}
          currentPageIndex={page}
          onSelectPage={selectPage}
        />
      )}
    </div>
  );
}

const TABLE_COLUMNS = [
  {
    title: "Hash",
    renderer: (tx) => (
      <Link to={`${routes.TRANSACTION}/${tx.hash}`}>
        <CommonStyles.Ellipsis>{tx.hash}</CommonStyles.Ellipsis>
      </Link>
    ),
    width: "15%",
  },
  {
    title: "Amount",
    renderer: (tx) => (
      <CommonStyles.Ellipsis>
        {currencyUtils.prettifyCurrencyString(tx.amount, 5)}
      </CommonStyles.Ellipsis>
    ),
    width: "12.5%",
  },
  {
    title: "Fee",
    renderer: (tx) => (
      <CommonStyles.Ellipsis>
        {currencyUtils.prettifyCurrencyString(tx.fee)}
      </CommonStyles.Ellipsis>
    ),
    width: "12.5%",
    hideOnMobile: true,
  },
  {
    title: "Sender",
    renderer: (tx) => (
      <Link to={`${routes.WALLET}/${tx.sender}`}>
        <CommonStyles.Ellipsis>{tx.sender}</CommonStyles.Ellipsis>
      </Link>
    ),
    width: "15%",
  },
  {
    title: "Recipient",
    renderer: (tx) => (
      <Link to={`${routes.WALLET}/${tx.recipient}`}>
        <CommonStyles.Ellipsis>{tx.recipient}</CommonStyles.Ellipsis>
      </Link>
    ),
    width: "15%",
  },
  {
    title: "Time",
    renderer: (tx) => (
      <CommonStyles.Ellipsis>
        {dateFns.format(new Date(tx.timestamp), "dd MMM, HH:mm:ss")}
      </CommonStyles.Ellipsis>
    ),
    width: "15%",
    hideOnMobile: true,
  },
  {
    title: "Block",
    key: "blockNumber",
    renderer: (tx) => (
      <CommonStyles.Ellipsis>{tx.blockNumber}</CommonStyles.Ellipsis>
    ),
    width: "15%",
    hideOnMobile: true,
  },
];
