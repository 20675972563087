import { handleActions } from "redux-actions";
import { LOCATION_CHANGE } from "connected-react-router";
import * as actions from "@search/actions";
import * as objectUtils from "@utils/objectUtils";

const initialState = {
  isSearchPending: false,
  searchError: false
};

export const searchReducer = handleActions(
  {
    [actions.search]: (state, { payload }) =>
      objectUtils.mergeDeepRight(state, {
        isSearchPending: true
      }),
    [actions.searchFailure]: (state, { payload }) =>
      objectUtils.mergeDeepRight(state, {
        searchError: payload,
        isSearchPending: false
      }),
    [actions.searchSuccess]: (state, { payload }) =>
      objectUtils.mergeDeepRight(state, {
        searchError: null,
        isSearchPending: false
      }),
    [LOCATION_CHANGE]: (state, { payload }) =>
      objectUtils.mergeDeepRight(state, {
        searchError: null
      })
  },
  initialState
);
