// @ts-check

import { transparentize, darken } from "polished";

export const COLORS = {
  BLACKEST_EVER_BLACK: "#000000",
  BLACK: "#1D1D1D",
  ORANGE: "#DA7600",
  GOLDEN: "#DB9E00",
  QUARTER_OPAQUE_GOLDEN: transparentize(0.25, "#DB9E00"),
  GRAY: "#C4C4C4",
  DARK_GRAY: "#8e8e8e",
  DARKER_GRAY: darken(0.4, "#8e8e8e"),
  RED: "#D02121",
  WHITE: "#FFFFFF",
  TRANSPARENT: "transparent",
};
