// Libraries
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
// Components
import { SearchToggle } from "@app/components/SearchToggle";
import { Search } from "@common/components/Search";
// Selectors
import * as selectors from "@app/selectors";
// Hooks
import { useGlobalSearch } from "@search/hooks/useGlobalSearch";
// Routes
import * as routes from "@routes";
// Styles
import * as Styles from "./styles";

export const Navigation = () => {
  const { searchError, isSearchPending, performSearch } = useGlobalSearch();
  const currentRoute = useSelector(selectors.pathnameSelector);

  const [searchQuery, setSearchQuery] = useState("");
  const [isSearchActive, setIsSearchActive] = useState(false);
  const [isSearchTouched, setIsSearchTouched] = useState(false);

  const handleSearchQueryChange = (event) => setSearchQuery(event.target.value);

  const handleSearchSubmit = (query) => {
    performSearch({ query });
    setIsSearchTouched(true);
    setSearchQuery("");
  };

  const handleSearchToggleClick = () => {
    if (isSearchActive) {
      setSearchQuery("");
      setIsSearchTouched(false);
    }
    setIsSearchActive(!isSearchActive);
  };

  useEffect(() => {
    if (isSearchTouched && !isSearchPending && !searchError) {
      setIsSearchTouched(false);
      setIsSearchActive(false);
    }
  }, [isSearchTouched, searchError, isSearchPending]);

  return (
    <Styles.Wrapper>
      {isSearchActive && (
        <Search
          query={searchQuery}
          error={isSearchTouched && !isSearchPending ? searchError : null}
          onChange={handleSearchQueryChange}
          onSubmit={handleSearchSubmit}
        />
      )}
      {!isSearchActive && (
        <>
          <Styles.EexInternalLink
            isActive={currentRoute === routes.LATEST_TRANSACTIONS}
            to={routes.LATEST_TRANSACTIONS}
          >
            Transactions
          </Styles.EexInternalLink>
          <Styles.EexInternalLink
            isActive={currentRoute === routes.LATEST_BLOCKS}
            to={routes.LATEST_BLOCKS}
          >
            Blocks
          </Styles.EexInternalLink>
          <Styles.EexInternalLink
            isActive={currentRoute === routes.STATS}
            to={routes.STATS}
          >
            Stats
          </Styles.EexInternalLink>
          <Styles.EexExternalLink
            href="https://wallet.ethereum-express.com/"
            target="_blank"
            hideOnMobile={true}
          >
            Wallet
          </Styles.EexExternalLink>
          <Styles.EexExternalLink
            href="https://cex.ethereum-express.com/"
            target="_blank"
            hideOnMobile={true}
          >
            Exchange
          </Styles.EexExternalLink>
        </>
      )}
      <SearchToggle
        isActive={isSearchActive}
        onClick={handleSearchToggleClick}
      />
    </Styles.Wrapper>
  );
};
