import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  width: 142px;
  height: 54px;
`;

export const Text = styled.div`
  margin-left: 22px;
  font-family: "Fira Sans", sans-serif;
  font-weight: 300;
  text-transform: uppercase;
  font-size: 14px;
  line-height: 14px;
  color: #db9e00;
`;
