import React from "react";

// Styles
import * as Styles from "./styles";

// Components
import Logo from "@common/components/Logo";

export default function Loader({ theme, minHeight }) {
  return (
    <Styles.Wrapper minHeight={minHeight}>
      <Styles.OpacityContainer>
        <Styles.Loader theme={theme} />
        <Styles.ImageContainer>
          <Logo />
        </Styles.ImageContainer>
      </Styles.OpacityContainer>
    </Styles.Wrapper>
  );
}
