import React from "react";
import styled from "styled-components";
import breakpoints from "@constants/breakpoints";
import { Link } from "react-router-dom";

export const SearchWrapper = styled.div`
  width: 70%;
  margin-bottom: 30px;
`;

export const Wrapper = styled.nav`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow-x: hidden;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 30px 0;

  background: black;

  font-size: 20px;

  @media (max-height: ${breakpoints.smToMd}px) {
    padding-top: 90px;
  }

  @media (orientation: landscape) and (max-width: ${breakpoints.smToMd}px) {
    justify-content: flex-start;
  }
`;

export const Line = styled.div`
  width: 70%;
  height: 1px;
  margin: 30px 0;
  flex-shrink: 0;

  background: rgba(255, 255, 255, 0.5);
`;

export const EexExternalLink = styled.a`
  color: #ffffff;
  font-family: Roboto;
  font-size: 20px;
  line-height: 40px;
`;

export const EexInternalLink = styled(({ isActive, ...props }) => (
  <Link {...props} />
))`
  color: ${({ isActive }) => (isActive ? "#DB9E00" : "#FFFFFF")};
  font-family: Roboto;
  font-size: 20px;
  line-height: 40px;
`;
