export const computeNormalizationCoeffs = (coeffDescriptions, items) => {
  const coeffNames = Array.from(Object.keys(coeffDescriptions));
  let boundaries = new Map(
    coeffNames.map((coeffName) => [
      coeffName,
      { minValue: +Infinity, maxValue: -Infinity },
    ])
  );
  for (const item of items) {
    for (const coeffName of coeffNames) {
      const coeffBoundaries = boundaries.get(coeffName);
      const coeffKeys = coeffDescriptions[coeffName];
      for (const key of coeffKeys) {
        const value = item[key];
        coeffBoundaries.minValue = Math.min(coeffBoundaries.minValue, value);
        coeffBoundaries.maxValue = Math.max(coeffBoundaries.maxValue, value);
      }
    }
  }
  let result = {};
  for (const coeffName of coeffNames) {
    const { minValue, maxValue } = boundaries.get(coeffName);
    const delta = maxValue - minValue;
    result[coeffName] = {
      offset: -minValue,
      normCoeff: delta < Number.EPSILON ? 1 : 1 / delta,
    };
  }
  return result;
};

export const mercatorProjection = (radius, x, y) => {
  const denormalizedX = -radius * Math.PI + 2 * radius * Math.PI * x;
  const denormalizedY = -Math.PI + y * 2 * Math.PI;
  const longitude = denormalizedX / radius;
  const latitude =
    2 * Math.atan(Math.exp(denormalizedY / radius)) - Math.PI / 2;
  return {
    x: radius * Math.cos(latitude) * Math.cos(longitude),
    y: radius * Math.cos(latitude) * Math.sin(longitude),
    z: radius * Math.sin(latitude),
  };
};

export const lerp = (a, b, t) => a + (b - a) * t;
