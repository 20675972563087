import { call } from "redux-saga/effects";
import pMinDelay from "p-min-delay";
import * as constants from "@constants";

/**
 *
 * @param {number} [minDelay]
 * @returns {(url: string, options: *) => *}
 */
const fetchWithMinDelay = minDelay => (url, options) => {
  return pMinDelay(fetch(url, options), minDelay ?? constants.API_MIN_DELAY_MS);
};

/**
 * @typedef {Object} ApiResponse
 * @property {*} [error]
 * @property {*} [result]
 */

/**
 * @generator
 * @callback ApiCall
 * @param {string} endpoint
 * @param {*} [options]
 * @yields {ApiResponse}
 */

/**
 *
 * @param {string} baseUrl
 * @param {*} [apiOptions]
 * @returns {ApiCall}
 */
export const apiCaller = (baseUrl, apiOptions) => {
  const defaultResultTransformer = apiOptions?.resultTransformer ?? (result => result);
  const defaultErrorTransformer = apiOptions?.errorTransformer ?? (error => error);
  return function*(endpoint, options) {
    try {
      const response = yield call(fetchWithMinDelay(options?.minDelay), baseUrl + endpoint, options);
      const resultBodyType = options?.resultBodyType ?? "json";
      const errorBodyType = options?.errorBodyType ?? "json";
      const resultTransformer = options?.resultTransformer ?? defaultResultTransformer;
      const errorTransformer = options?.errorTransformer ?? defaultErrorTransformer;
      return response.ok
        ? {
            result: yield call(resultTransformer, yield parseBody(response, resultBodyType), response.headers)
          }
        : {
            error: yield call(errorTransformer, yield parseBody(response, errorBodyType), response.headers)
          };
    } catch (error) {
      return {
        error: {
          message: error.message
        }
      };
    }
  };
};

/**
 * Parse body accordingly to specified type
 * @param {*} response
 * @param {string} bodyType Can be 'json' or 'text'
 * @returns {*}
 */
function parseBody(response, bodyType) {
  // In case more body types needed, check what else is available:
  // https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API/Using_Fetch#Response_objects
  switch (bodyType) {
    case "json":
      return call([response, response.json]);
    case "text":
      return call([response, response.text]);
    default:
      throw new Error(`Unknown body type: ${bodyType}`);
  }
}

/**
 *
 * @param {(result: *) => *} fn
 * @returns {(response: ApiResponse) => ApiResponse}
 */
export const mapResult = fn => response => {
  return response.error ? response : { result: fn(response.result) };
};

/**
 *
 * @param {(error: *) => *} fn
 * @returns {(response: ApiResponse) => ApiResponse}
 */
export const mapError = fn => response => {
  return response.error ? { error: fn(response.error) } : response;
};
