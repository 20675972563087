import { put, takeLatest } from "redux-saga/effects";
import * as explorerApi from "@api/explorerApi";
import * as actions from "@stats/actions";

export function* fetchTransactionsHistory({ payload: { limit } }) {
  const { error, result } = yield* explorerApi.getTransactionsHistory({ limit });
  if (error) {
    yield put(actions.fetchTransactionsHistoryFailure(error));
  } else {
    yield put(actions.fetchTransactionsHistorySuccess(result));
  }
}

export function* watchFetchTransactionsHistory() {
  yield takeLatest(actions.FETCH_TRANSACTIONS_HISTORY, fetchTransactionsHistory);
}

export function* fetchPriceHistory({ payload: { limit } }) {
  const { error, result } = yield* explorerApi.getPriceHistory({ limit });
  if (error) {
    yield put(actions.fetchPriceHistoryFailure(error));
  } else {
    yield put(actions.fetchPriceHistorySuccess(result));
  }
}

export function* watchFetchPriceHistory() {
  yield takeLatest(actions.FETCH_PRICE_HISTORY, fetchPriceHistory);
}
