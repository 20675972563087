/* eslint-disable import/no-webpack-loader-syntax */

import { useState, useEffect, useRef } from "react";
import springyWorker from "workerize-loader!./useSpringy.worker";

const NODES_KEY = "useSpringy:nodes";
const EDGES_KEY = "useSpringy:edges";
const VERTICES_KEY = "useSpringy:animatedVertices";

export const useSpringy = ({
  nodes,
  edges,
  stiffness = 40.0,
  repulsion = 400.0,
  damping = 0.5,
  layoutPrecalculatedTicksCount = 0,
  layoutRecalculationTicksCount = 1,
  layoutRecalculationTickDuration = 0.03,
  updateGeometryIntervalMillis = 2000,
  cacheIntervalMillis = 2500,
  shouldCache = false,
}) => {
  const [worker] = useState(() => springyWorker());
  const [animatedVertices, setAnimatedVertices] = useState([]);
  const [isFromCache, setIsFromCache] = useState(false);

  const nodesRef = useRef();
  const edgesRef = useRef();
  const animatedVerticesRef = useRef();

  const isCacheObsolete = () => {
    const cacheNodes = JSON.parse(localStorage.getItem(NODES_KEY)) ?? [];
    const cacheEdges = JSON.parse(localStorage.getItem(EDGES_KEY)) ?? [];
    if (
      nodesRef.current.length !== cacheNodes.length ||
      edgesRef.current.length !== cacheEdges.length
    ) {
      return true;
    }
    const nodesSet = new Set(nodesRef.current);
    if (cacheNodes.some((node) => !nodesSet.has(node))) return true;
    const edgesSet = new Set(
      edgesRef.current.map(([from, to]) => `${from}_${to}`)
    );
    if (cacheEdges.some(([from, to]) => !edgesSet.has(`${from}_${to}`)))
      return true;
    return false;
  };

  const persistToCache = () => {
    localStorage.setItem(NODES_KEY, JSON.stringify(nodesRef.current));
    localStorage.setItem(EDGES_KEY, JSON.stringify(edgesRef.current));
    localStorage.setItem(
      VERTICES_KEY,
      JSON.stringify(animatedVerticesRef.current)
    );
  };

  useEffect(() => {
    nodesRef.current = nodes;
    edgesRef.current = edges;
    animatedVerticesRef.current = animatedVertices;
  }, [nodes, edges, animatedVertices]);

  useEffect(
    () => () => {
      shouldCache && persistToCache();
    },
    []
  );

  useEffect(() => {
    if (nodes.length > 0 && edges.length > 0) {
      let isWorkerCalculating = false;

      (async () => {
        isWorkerCalculating = true;
        await worker.initialize({
          nodes,
          edges,
          stiffness,
          repulsion,
          damping,
        });
        if (shouldCache && !isCacheObsolete()) {
          const animatedVertices = JSON.parse(
            localStorage.getItem(VERTICES_KEY)
          );
          await worker.setCurrentData(animatedVertices);
          setIsFromCache(true);
          setAnimatedVertices(animatedVertices);
        } else {
          await worker.calculate(
            layoutRecalculationTickDuration,
            layoutPrecalculatedTicksCount
          );
          const precalculatedAnimatedVertices = await worker.getCurrentData();
          animatedVerticesRef.current = precalculatedAnimatedVertices;
          persistToCache();
          setAnimatedVertices(precalculatedAnimatedVertices);
        }
        isWorkerCalculating = false;
      })();

      const calculateIntervalHandle = setInterval(() => {
        if (!isWorkerCalculating) {
          isWorkerCalculating = true;
          worker
            .calculate(
              layoutRecalculationTickDuration,
              layoutRecalculationTicksCount
            )
            .then(() => (isWorkerCalculating = false));
        }
      }, 0);

      const updateGeometryIntervalHandle = setInterval(() => {
        worker.getCurrentData().then(setAnimatedVertices);
      }, updateGeometryIntervalMillis);

      const cacheIntervalHandle =
        shouldCache && setInterval(persistToCache, cacheIntervalMillis);

      return () => {
        clearInterval(calculateIntervalHandle);
        clearInterval(updateGeometryIntervalHandle);
        clearInterval(cacheIntervalHandle);
      };
    }
  }, [worker, nodes, edges, stiffness, repulsion, damping]);

  return {
    animatedVertices,
    isFromCache,
  };
};
