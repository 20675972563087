import { connectRouter } from "connected-react-router";
import { combineReducers } from "redux";
import { searchReducer } from "@search/reducers";
import { walletsReducer } from "@wallets/reducers";
import { transactionsReducer } from "@transactions/reducers";
import { blocksReducer } from "@blocks/reducers";
import { statsReducer } from "@stats/reducers";
import { graphsReducer } from "@graphs/reducers";

export const createRootReducer = history =>
  combineReducers({
    router: connectRouter(history),
    search: searchReducer,
    wallets: walletsReducer,
    transactions: transactionsReducer,
    blocks: blocksReducer,
    stats: statsReducer,
    graphs: graphsReducer
  });
