import { put, takeLatest } from "redux-saga/effects";
import { push } from "connected-react-router";
import * as actions from "@graphs/actions";
import * as routes from "@routes";

export function* expandTransaction({ payload: { hash } }) {
  yield put(push(`${routes.TRANSACTION}/${hash}`));
}

export function* watchExpandTransaction() {
  yield takeLatest(actions.EXPAND_TRANSACTION, expandTransaction);
}
