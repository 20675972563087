import styled from "styled-components";
import breakpoints from "@constants/breakpoints";
import { Link } from "react-router-dom";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 202px;
  padding: 30px 40px;
  box-sizing: border-box;
  background: #000000;
  color: #ffffff;
`;

export const LinksSection = styled.section`
  display: flex;
  padding-bottom: 30px;

  @media (max-width: ${breakpoints.md}px) {
    flex-direction: column;
    align-items: center;
    & > *:not(:first-child) {
      margin-top: 30px;
    }
  }
`;

export const EexLinks = styled.div`
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 70px;

  & > *:not(:first-child) {
    margin-left: 38px;
  }

  @media (max-width: ${breakpoints.md}px) {
    display: none;
  }
`;

export const EexExternalLink = styled.a`
  font-family: Roboto;
  font-size: 15px;
  line-height: 18px;
  color: #ffffff;
`;

export const EexInternalLink = styled(Link)`
  font-family: Roboto;
  font-size: 15px;
  line-height: 18px;
  color: #ffffff;
`;

export const SocialWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: ${breakpoints.md}px) {
    flex-direction: column;
  }
`;

export const SocialLinks = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  & > a:not(:first-child) {
    margin-left: 14px;
  }
`;

export const SocialText = styled.span`
  user-select: none;

  @media (min-width: ${breakpoints.md}px) {
    margin-right: 20px;
  }

  @media (max-width: ${breakpoints.md}px) {
    margin-bottom: 14px;
  }
`;

export const SocialLink = styled.a`
  & > img {
    width: 22px;
    height: 22px;
  }

  @media (max-width: ${breakpoints.md}px) {
    & > img {
      width: 30px;
      height: 30px;
    }
  }
`;

export const CopyrightSection = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 34px;
  border-top: 1px solid rgba(255, 255, 255, 0.4);

  color: rgba(255, 255, 255, 0.6);

  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 19px;
  text-align: center;
`;

export const MobileLineBreak = styled.br`
  @media (min-width: ${breakpoints.md}px) {
    display: none;
  }
`;
