// React
import React from "react";
// General
import * as constants from "@constants";
// Components
import { GlobalTransactions2d } from "@graphs/components/2d/GlobalTransactions2d";
import { LatestGraphTransactions } from "@graphs/components/dom/LatestGraphTransactions";
import { DimensionsSwitch } from "@graphs/components/dom/DimensionsSwitch";
import { GraphSearch } from "@graphs/components/dom/GraphSearch";
// Styles
import * as Styles from "./styles";
import * as CommonStyles from "@common/styles";

export const GlobalTransactions = () => (
  <Styles.Wrapper>
    <Styles.SearchAndTableWrapper>
      <CommonStyles.Header theme={constants.THEMES.DARK}>
        Search Latest Transactions
      </CommonStyles.Header>
      <Styles.SearchWrapper>
        <GraphSearch />
      </Styles.SearchWrapper>
      <LatestGraphTransactions
        itemsPerPage={constants.TRANSACTIONS_PER_PAGE}
        hidePagination={false}
      />
    </Styles.SearchAndTableWrapper>
    <Styles.GraphWrapper>
      <DimensionsSwitch
        initialDimensions={constants.DIMENSIONS.TWO}
        twoDimensionalComponent={() => <GlobalTransactions2d />}
        threeDimensionalComponent={() => null}
      />
    </Styles.GraphWrapper>
  </Styles.Wrapper>
);
