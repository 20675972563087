import React from "react";
import * as arrayUtils from "@utils/arrayUtils";
import * as Styles from "./styles";

export default function Paginator({
  pagesCount,
  currentPageIndex,
  intermediatePagesCount = 3,
  highlightedPageIndexes,
  onSelectPage,
  theme,
}) {
  const lastPageIndex = pagesCount;

  const intermediateSegmentStartIndex = Math.max(
    2,
    Math.min(currentPageIndex - 1, lastPageIndex - intermediatePagesCount)
  );

  const intermediateSegmentEndIndex = Math.min(
    lastPageIndex - 1,
    intermediateSegmentStartIndex + intermediatePagesCount - 1
  );

  const intermediateSegmentPageIndexes = arrayUtils.range(
    intermediateSegmentStartIndex,
    intermediateSegmentEndIndex
  );

  const handleSelectPrevPage = () =>
    onSelectPage(Math.max(1, currentPageIndex - 5));
  const handleSelectNextPage = () =>
    onSelectPage(Math.min(lastPageIndex, currentPageIndex + 5));
  const handleSelectPage = (event) =>
    onSelectPage(Number(event.target.dataset.index));

  return (
    <Styles.Wrapper>
      <Styles.PageButton
        theme={theme}
        disabled={currentPageIndex === 1}
        onClick={handleSelectPrevPage}
      >
        {"«"}
      </Styles.PageButton>
      <Styles.PageButton
        theme={theme}
        active={currentPageIndex === 1}
        highlighted={highlightedPageIndexes?.has(1)}
        data-index={1}
        onClick={handleSelectPage}
      >
        1
      </Styles.PageButton>
      {intermediateSegmentStartIndex !== 2 && (
        <Styles.PageButton theme={theme} disabled>
          {"…"}
        </Styles.PageButton>
      )}
      {intermediateSegmentPageIndexes.map((i) => (
        <Styles.PageButton
          theme={theme}
          key={i}
          active={i === currentPageIndex}
          highlighted={highlightedPageIndexes?.has(i)}
          data-index={i}
          onClick={handleSelectPage}
        >
          {i}
        </Styles.PageButton>
      ))}
      {intermediateSegmentEndIndex !== lastPageIndex - 1 && (
        <Styles.PageButton theme={theme} disabled>
          {"…"}
        </Styles.PageButton>
      )}
      {pagesCount > 1 && (
        <Styles.PageButton
          theme={theme}
          active={currentPageIndex === lastPageIndex}
          highlighted={highlightedPageIndexes?.has(lastPageIndex)}
          data-index={lastPageIndex}
          onClick={handleSelectPage}
        >
          {lastPageIndex}
        </Styles.PageButton>
      )}
      <Styles.PageButton
        theme={theme}
        disabled={currentPageIndex === lastPageIndex}
        onClick={handleSelectNextPage}
      >
        {"»"}
      </Styles.PageButton>
    </Styles.Wrapper>
  );
}
