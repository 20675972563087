// React
import React from "react";
import { Link, useParams } from "react-router-dom";
// Utils
import * as dateFns from "date-fns";
import * as currencyUtils from "@utils/currencyUtils";
// Routes
import * as routes from "@routes";
// Constants
import * as constants from "@constants";
// Hooks
import { useBlockDetails } from "@blocks/hooks";
// Components
import Table from "@common/components/Table";
import EntityNotFound from "@common/components/EntityNotFound";
import Loader from "@common/components/Loader";
import Paginator from "@common/components/Paginator";
// Styles
import * as TableStyles from "@common/components/Table/styles";
import * as CommonStyles from "@common/styles";

export default function BlockDetails() {
  const { hash: queryHash } = useParams();

  const {
    hash,
    amount,
    height,
    isFetchingInfo,
    fetchInfoError,
    transactionsCount,
    transactions,
    transactionsPage,
    transactionsPagesCount,
    isFetchingTransactions,
    fetchTransactionsError,
    selectTransactionsPage,
  } = useBlockDetails({
    hash: queryHash,
  });
  if (isFetchingInfo) {
    return <Loader theme={constants.THEMES.LIGHT} />;
  }
  if (fetchInfoError || fetchTransactionsError) {
    return <EntityNotFound name="Block" />;
  }
  return (
    <div>
      <CommonStyles.Header>Block</CommonStyles.Header>
      <CommonStyles.Card.Wrapper>
        <CommonStyles.Card.Row>
          <div>Hash</div>
          <CommonStyles.Ellipsis>{hash}</CommonStyles.Ellipsis>
        </CommonStyles.Card.Row>
        <CommonStyles.Card.Row>
          <div>Balance</div>
          <CommonStyles.CurrencyWrapper>
            <CommonStyles.Ellipsis>
              {currencyUtils.prettifyCurrencyString(amount)}
            </CommonStyles.Ellipsis>{" "}
            EEX
          </CommonStyles.CurrencyWrapper>
        </CommonStyles.Card.Row>
        <CommonStyles.Card.Row>
          <div>Txn</div>
          <CommonStyles.Ellipsis>{transactionsCount}</CommonStyles.Ellipsis>
        </CommonStyles.Card.Row>
        <CommonStyles.Card.Row>
          <div>Height</div>
          <CommonStyles.Ellipsis>{height}</CommonStyles.Ellipsis>
        </CommonStyles.Card.Row>
      </CommonStyles.Card.Wrapper>
      {(isFetchingTransactions || transactions.length > 0) && (
        <>
          <CommonStyles.Header>Transactions</CommonStyles.Header>
          <CommonStyles.Card.Wrapper>
            {isFetchingTransactions ? (
              <Loader
                theme={constants.THEMES.LIGHT}
                minHeight={`${
                  (1 + constants.TRANSACTIONS_PER_PAGE) *
                  TableStyles.ROW_HEIGHT_PX
                }px`}
              />
            ) : (
              <Table columns={TABLE_COLUMNS} items={transactions} />
            )}
          </CommonStyles.Card.Wrapper>
        </>
      )}
      {transactionsPagesCount > 1 && (
        <Paginator
          pagesCount={transactionsPagesCount}
          currentPageIndex={transactionsPage}
          onSelectPage={selectTransactionsPage}
        />
      )}
    </div>
  );
}

const TABLE_COLUMNS = [
  {
    title: "Hash",
    key: "hash",
    renderer: (tx) => (
      <Link to={`${routes.TRANSACTION}/${tx.hash}`}>
        <CommonStyles.Ellipsis>{tx.hash}</CommonStyles.Ellipsis>
      </Link>
    ),
  },
  {
    title: "Amount",
    key: "amount",
    renderer: (tx) => (
      <CommonStyles.Ellipsis>
        {currencyUtils.prettifyCurrencyString(tx.amount)}
      </CommonStyles.Ellipsis>
    ),
  },
  {
    title: "Fee",
    key: "fee",
    renderer: (tx) => (
      <CommonStyles.Ellipsis>
        {currencyUtils.prettifyCurrencyString(tx.fee)}
      </CommonStyles.Ellipsis>
    ),
    hideOnMobile: true,
  },
  {
    title: "Sender",
    key: "sender",
    renderer: (tx) => (
      <Link to={`${routes.WALLET}/${tx.sender}`}>
        <CommonStyles.Ellipsis>{tx.sender}</CommonStyles.Ellipsis>
      </Link>
    ),
  },
  {
    title: "Recipient",
    key: "recipient",
    renderer: (tx) => (
      <Link to={`${routes.WALLET}/${tx.recipient}`}>
        <CommonStyles.Ellipsis>{tx.recipient}</CommonStyles.Ellipsis>
      </Link>
    ),
  },
  {
    title: "Time",
    key: "timestamp",
    renderer: (tx) => (
      <CommonStyles.Ellipsis>
        {dateFns.format(new Date(tx.timestamp), "dd MMM, HH:mm:ss")}
      </CommonStyles.Ellipsis>
    ),
    hideOnMobile: true,
  },
  {
    title: "Block",
    key: "blockNumber",
    renderer: (tx) => (
      <CommonStyles.Ellipsis>{tx.blockNumber}</CommonStyles.Ellipsis>
    ),
    hideOnMobile: true,
  },
];
