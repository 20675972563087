// React
import React from "react";
// Components
import HistoryChart from "@common/components/HistoryChart";
// Hooks
import useTransactionsHistory from "@stats/hooks/useTransactionsHistory";

export default function TransactionHistoryChart() {
  return (
    <HistoryChart
      title="Transactions History"
      useHistoryData={useTransactionsHistory}
      lineDataKey="amount"
      xAxisDataKey="day"
    />
  );
}
