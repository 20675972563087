// @ts-check

import { createAction } from "redux-actions";

export const FETCH_LATEST_BLOCKS = "FETCH_LATEST_BLOCKS";
export const FETCH_LATEST_BLOCKS_FAILURE = "FETCH_LATEST_BLOCKS_FAILURE";
export const FETCH_LATEST_BLOCKS_SUCCESS = "FETCH_LATEST_BLOCKS_SUCCESS";
export const FETCH_LATEST_BLOCKS_SUCCESS_FROM_CACHE = "FETCH_LATEST_BLOCKS_SUCCESS_FROM_CACHE";

export const fetchLatestBlocks = createAction(FETCH_LATEST_BLOCKS);
export const fetchLatestBlocksFailure = createAction(FETCH_LATEST_BLOCKS_FAILURE);
export const fetchLatestBlocksSuccess = createAction(FETCH_LATEST_BLOCKS_SUCCESS);
export const fetchLatestBlocksSuccessFromCache = createAction(FETCH_LATEST_BLOCKS_SUCCESS_FROM_CACHE);

export const FETCH_BLOCK_DETAILS = "FETCH_BLOCK_DETAILS";
export const FETCH_BLOCK_DETAILS_SUCCESS = "FETCH_BLOCK_DETAILS_SUCCESS";
export const FETCH_BLOCK_DETAILS_FAILURE = "FETCH_BLOCK_DETAILS_FAILURE";

export const fetchBlockDetails = createAction(FETCH_BLOCK_DETAILS);
export const fetchBlockDetailsSuccess = createAction(FETCH_BLOCK_DETAILS_SUCCESS);
export const fetchBlockDetailsFailure = createAction(FETCH_BLOCK_DETAILS_FAILURE);

export const FETCH_BLOCK_TRANSACTIONS = "FETCH_BLOCK_TRANSACTIONS";
export const FETCH_BLOCK_TRANSACTIONS_SUCCESS = "FETCH_BLOCK_TRANSACTIONS_SUCCESS";
export const FETCH_BLOCK_TRANSACTIONS_FAILURE = "FETCH_BLOCK_TRANSACTIONS_FAILURE";

export const fetchBlockTransactions = createAction(FETCH_BLOCK_TRANSACTIONS);
export const fetchBlockTransactionsSuccess = createAction(FETCH_BLOCK_TRANSACTIONS_SUCCESS);
export const fetchBlockTransactionsFailure = createAction(FETCH_BLOCK_TRANSACTIONS_FAILURE);
