import { put, takeLatest, all, call } from "redux-saga/effects";
import { push } from "connected-react-router";
import * as explorerApi from "@api/explorerApi";
import * as actions from "@search/actions";
import * as routes from "@routes";
import * as constants from "@constants";

export function* search({ payload: { query } }) {
  if (
    query.length !== constants.WALLET_HASH_LENGTH &&
    query.length !== constants.TRANSACTION_HASH_LENGTH
  ) {
    return yield put(
      actions.searchFailure(constants.SEARCH_ERROR.INVALID_FORMAT)
    );
  }
  const { transaction, wallet } = yield all({
    transaction: call(explorerApi.getTransactionDetails, query),
    wallet: call(explorerApi.getWalletDetails, query, { limit: 1 })
  });
  if (transaction.error && wallet.error) {
    if (query.length === constants.WALLET_HASH_LENGTH) {
      yield put(actions.searchFailure(constants.SEARCH_ERROR.WALLET_NOT_FOUND));
    } else if (query.length === constants.TRANSACTION_HASH_LENGTH) {
      yield put(
        actions.searchFailure(constants.SEARCH_ERROR.TRANSACTION_NOT_FOUND)
      );
    } else {
      throw new Error("Unreachable error");
    }
  } else {
    yield put(
      actions.searchSuccess({
        type: transaction.result ? "TRANSACTION" : "WALLET",
        hash: query
      })
    );
  }
}

export function* watchSearch() {
  yield takeLatest(actions.GLOBAL_SEARCH, search);
}

export function* redirectOnSearchSuccess() {
  yield takeLatest(actions.GLOBAL_SEARCH_SUCCESS, function*({
    payload: { type, hash }
  }) {
    switch (type) {
      case "TRANSACTION":
        yield put(push(`${routes.TRANSACTION}/${hash}`));
        return;
      case "WALLET":
        yield put(push(`${routes.WALLET}/${hash}`));
        return;
      default:
        throw new Error(`Unknown search result type: "${type}"`);
    }
  });
}
