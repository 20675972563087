// React
import React from "react";
import { Link } from "react-router-dom";
// Utils
import * as dateFns from "date-fns";
import * as currencyUtils from "@utils/currencyUtils";
// Routes
import * as routes from "@routes";
// Hooks
import { useLatestBlocks } from "@blocks/hooks";
// Components
import Table from "@common/components/Table";
import EntityNotFound from "@common/components/EntityNotFound";
import Loader from "@common/components/Loader";
import Paginator from "@common/components/Paginator";
// Constants
import * as constants from "@constants";
// Styles
import * as TableStyles from "@common/components/Table/styles";
import * as CommonStyles from "@common/styles";

export default function LatestBlocks({ itemsPerPage, hidePagination }) {
  const {
    blocks,
    page,
    pagesCount,
    isFetching,
    fetchError,
    selectPage,
  } = useLatestBlocks(itemsPerPage);
  if (fetchError) {
    return <EntityNotFound name="Transactions" />;
  }
  return (
    <div>
      {(isFetching || blocks.length > 0) && (
        <>
          <CommonStyles.Header theme={constants.THEMES.LIGHT}>
            Latest Blocks
          </CommonStyles.Header>
          <CommonStyles.Card.Wrapper>
            {isFetching ? (
              <Loader
                theme={constants.THEMES.LIGHT}
                minHeight={`${
                  (1 + itemsPerPage) * TableStyles.ROW_HEIGHT_PX
                }px`}
              />
            ) : (
              <Table
                theme={constants.THEMES.LIGHT}
                columns={TABLE_COLUMNS}
                items={blocks}
              />
            )}
          </CommonStyles.Card.Wrapper>
        </>
      )}
      {!hidePagination && pagesCount > 1 && (
        <Paginator
          theme={constants.THEMES.LIGHT}
          pagesCount={pagesCount}
          currentPageIndex={page}
          onSelectPage={selectPage}
        />
      )}
    </div>
  );
}

const TABLE_COLUMNS = [
  {
    title: "Height",
    key: "height",
    renderer: (block) => (
      <CommonStyles.Ellipsis>{block.height}</CommonStyles.Ellipsis>
    ),
    width: "15%",
    hideOnMobile: true,
  },
  {
    title: "Hash",
    renderer: (block) => (
      <Link to={`${routes.BLOCK_DETAILS}/${block.hash}`}>
        <CommonStyles.Ellipsis>{block.hash}</CommonStyles.Ellipsis>
      </Link>
    ),
    width: "45%",
  },
  {
    title: "Time",
    renderer: (block) => (
      <CommonStyles.Ellipsis>
        {dateFns.format(new Date(block.timestamp), "dd MMM, HH:mm:ss")}
      </CommonStyles.Ellipsis>
    ),
    width: "15%",
    hideOnMobile: true,
  },
  {
    title: "Txn",
    key: "transactionsCount",
    width: "10%",
    hideOnMobile: true,
  },
  {
    title: "Amount",
    renderer: (block) => (
      <CommonStyles.Ellipsis>
        {currencyUtils.prettifyCurrencyString(block.amount, 5)}
      </CommonStyles.Ellipsis>
    ),
    width: "15%",
  },
];
