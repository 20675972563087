import * as geometryUtils from "@utils/geometryUtils";

export const bakeVertices = (backendVertices, animatedVertices) => {
  const {
    size: { offset: offsetSize, normCoeff: normCoeffSize },
  } = geometryUtils.computeNormalizationCoeffs(
    { size: ["size"] },
    backendVertices
  );
  const {
    x: { offset: offsetX, normCoeff: normCoeffX },
    y: { offset: offsetY, normCoeff: normCoeffY },
    prevX: { offset: offsetPrevX, normCoeff: normCoeffPrevX },
    prevY: { offset: offsetPrevY, normCoeff: normCoeffPrevY },
  } = geometryUtils.computeNormalizationCoeffs(
    { x: ["x"], y: ["y"], prevX: ["prevX"], prevY: ["prevY"] },
    animatedVertices
  );
  const sizeByAddress = new Map(
    backendVertices.map((vertex) => [vertex.address, vertex.size])
  );
  const isFirstTime = animatedVertices.every(
    (vertex) => vertex.prevX === 0 && vertex.prevY === 0
  );
  return animatedVertices.map((vertex) => ({
    address: vertex.label,
    x: (offsetX + vertex.x) * normCoeffX,
    y: (offsetY + vertex.y) * normCoeffY,
    prevX: isFirstTime ? 0.5 : (offsetPrevX + vertex.prevX) * normCoeffPrevX,
    prevY: isFirstTime ? 0.5 : (offsetPrevY + vertex.prevY) * normCoeffPrevY,
    size: (offsetSize + sizeByAddress.get(vertex.label)) * normCoeffSize,
  }));
};
