import styled, { css } from "styled-components";
import { transparentize } from "polished";
import breakpoints from "@constants/breakpoints";
import * as constants from "@constants";

export const ROW_HEIGHT_PX = 50;

export const Table = styled.table`
  table-layout: fixed;
  border-spacing: 0;
  width: 100%;

  th {
    font-weight: normal;
    color: ${({ theme }) =>
      ({
        [constants.THEMES.LIGHT]: constants.COLORS.DARK_GRAY,
        [constants.THEMES.DARK]: constants.COLORS.WHITE,
      }[theme])};

    ${({ theme }) =>
      theme === constants.THEMES.DARK &&
      css`
        border-top: 1px solid ${transparentize(0.8, constants.COLORS.WHITE)};
        border-bottom: 1px solid ${transparentize(0.8, constants.COLORS.WHITE)};
      `}
  }

  ${({ theme }) =>
    theme === constants.THEMES.LIGHT &&
    css`
      & tr:not(:last-child) > * {
        border-bottom: 1px solid #e5e5e5;
      }
    `}

  ${({ theme }) =>
    theme === constants.THEMES.DARK &&
    css`
      & th,
      td,
      a {
        color: white;
      }
    `};
`;

export const TableRow = styled.tr`
  height: ${ROW_HEIGHT_PX}px;

  & > * {
    text-align: center;
  }

  ${({ theme, index, isSelected }) => css`
    background: ${getRowBackgroundColor({ theme, index, isSelected })};
  `}
`;

export const TableHeaderCell = styled.th`
  width: ${({ width }) => width ?? "initial"};

  @media (max-width: ${breakpoints.md}px) {
    ${({ hideOnMobile }) => hideOnMobile && `display: none`};
  }
`;

export const TableDataCell = styled.td`
  word-wrap: break-word;
  padding: 8px;

  @media (max-width: ${breakpoints.md}px) {
    ${({ hideOnMobile }) => hideOnMobile && `display: none`};
  }
`;

export const Ellipsis = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  ${({ display }) => display && `display: ${display};`}
  ${({ maxWidth }) => maxWidth && `max-width: ${maxWidth};`}
`;

const getRowBackgroundColor = ({ theme, index, isSelected }) => {
  const isEven = index % 2 === 0;
  const isOdd = !isEven;
  switch (true) {
    case theme === constants.THEMES.DARK && isSelected && isEven:
      return transparentize(0.5, constants.COLORS.GOLDEN);
    case theme === constants.THEMES.DARK && isSelected && isOdd:
      return transparentize(0.6, constants.COLORS.GOLDEN);
    case theme === constants.THEMES.DARK && !isSelected && isEven:
      return transparentize(0.8, constants.COLORS.WHITE);
    case theme === constants.THEMES.DARK && !isSelected && isOdd:
      return transparentize(0.85, constants.COLORS.GRAY);
    default:
      return "initial";
  }
};
