import { put, takeLatest } from "redux-saga/effects";
import * as explorerApi from "@api/explorerApi";
import * as actions from "@wallets/actions";
import * as constants from "@constants";

export function* fetchWalletDetails({ payload: { address } }) {
  const { error, result } = yield* explorerApi.getWalletDetails(address, { limit: 0 });
  if (error) {
    yield put(actions.fetchWalletDetailsFailure(error));
  } else {
    yield put(actions.fetchWalletDetailsSuccess(result));
  }
}

export function* watchFetchWalletDetails() {
  yield takeLatest(actions.FETCH_WALLET_DETAILS, fetchWalletDetails);
}

export function* fetchWalletTransactions({ payload: { address, page } }) {
  const { error, result } = yield* explorerApi.getWalletTransactions(address, {
    skip: constants.TRANSACTIONS_PER_PAGE * (page - 1),
    limit: constants.TRANSACTIONS_PER_PAGE
  });
  if (error) {
    yield put(actions.fetchWalletTransactionsFailure(error));
  } else {
    yield put(
      actions.fetchWalletTransactionsSuccess({
        ...result,
        page
      })
    );
  }
}

export function* watchFetchWalletTransactions() {
  yield takeLatest(actions.FETCH_WALLET_TRANSACTIONS, fetchWalletTransactions);
}
