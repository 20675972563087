// @ts-check

import { createPathSelector } from "@utils/selectorUtils";

const rootSelector = state => state.wallets;

const infoSelector = createPathSelector(rootSelector, "info");
export const addressSelector = createPathSelector(infoSelector, "address");
export const balanceSelector = createPathSelector(infoSelector, "balance");

const metaSelector = createPathSelector(infoSelector, "meta");
export const isFetchingSelector = createPathSelector(metaSelector, "isFetching");
export const fetchErrorSelector = createPathSelector(metaSelector, "fetchError");

const transactionsSelector = createPathSelector(rootSelector, "transactions");
export const transactionsTotalCountSelector = createPathSelector(transactionsSelector, "totalCount");
export const transactionsPageSelector = createPathSelector(transactionsSelector, "page");
export const transactionsItemsSelector = createPathSelector(transactionsSelector, "items");

const transactionsMetaSelector = createPathSelector(transactionsSelector, "meta");
export const transactionsIsFetchingSelector = createPathSelector(transactionsMetaSelector, "isFetching");
export const transactionsFetchErrorSelector = createPathSelector(transactionsMetaSelector, "fetchError");
