// React
import { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
// Selectors
import * as selectors from "@stats/selectors";
// Actions
import * as actions from "@stats/actions";
// Utils
import * as dateFns from "date-fns";
// Hooks
import useActions from "@utils/hooks/useActions";

export default function usePriceHistory(periodInDays) {
  const historyData = useSelector(selectors.priceHistoryItemsSelector);
  const { fetchPriceHistory } = useActions(actions);

  useEffect(() => {
    fetchPriceHistory({ limit: periodInDays });
  }, [fetchPriceHistory, periodInDays]);

  const formattedHistoryData = useMemo(
    () => historyData.map(item => ({ ...item, day: dateFns.format(new Date(item.date), "dd MMM") })),
    [historyData]
  );

  return {
    historyData: formattedHistoryData
  };
}
