import { put, takeLatest } from "redux-saga/effects";
import { push } from "connected-react-router";
import * as actions from "@graphs/actions";
import * as routes from "@routes";

export function* expandWallet({ payload: { address } }) {
  yield put(push(`${routes.WALLET}/${address}`));
}

export function* watchExpandWallet() {
  yield takeLatest(actions.EXPAND_WALLET, expandWallet);
}
