import styled, { keyframes } from "styled-components";
import breakpoints from "@constants/breakpoints";
import * as constants from "@constants";

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  min-height: ${({ minHeight }) => (minHeight == null ? "initial" : minHeight)};
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;

export const ImageContainer = styled.div`
  width: 140px;
  height: 140px;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: ${breakpoints.sm}px) {
    width: 100px;
    height: 100px;
  }
`;

const load3 = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
`;

export const Loader = styled.div`
  font-size: 24px;
  margin: 50px auto;
  text-indent: -9999em;
  width: 11em;
  height: 11em;
  border-radius: 50%;
  background: ${constants.COLORS.GOLDEN};
  background: linear-gradient(to right, ${constants.COLORS.GOLDEN} 10%, rgba(255, 255, 255, 0) 42%);
  position: relative;
  animation: ${load3} 1.4s infinite linear;
  transform: translateZ(0);

  &:before {
    width: 50%;
    height: 50%;
    background: ${constants.COLORS.GOLDEN};
    border-radius: 100% 0 0 0;
    position: absolute;
    top: 0;
    left: 0;
    content: "";
  }

  &:after {
    background: ${({ theme }) =>
      ({
        [constants.THEMES.LIGHT]: constants.COLORS.WHITE,
        [constants.THEMES.DARK]: constants.COLORS.BLACKEST_EVER_BLACK
      }[theme])};
    width: 90%;
    height: 90%;
    border-radius: 50%;
    content: "";
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }

  @media (max-width: ${breakpoints.sm}px) {
    font-size: 16px;
  }
`;

export const OpacityContainer = styled.div`
  opacity: 0.7;
  display: flex;
  justify-content: center;
  align-items: center;
`;
