import { handleActions } from "redux-actions";
import * as actions from "@transactions/actions";
import * as objectUtils from "@utils/objectUtils";

const initialState = {
  latestTransactions: {
    totalCount: 0,
    page: 0,
    limit: 0,
    byHash: {},
    allHashes: [],
    meta: {
      isFetching: false,
      fetchError: null
    }
  },
  transactionDetails: {
    hash: null,
    sender: null,
    recipient: null,
    amount: null,
    fee: null,
    timestamp: null,
    blockNumber: null,
    meta: {
      isFetching: false,
      fetchError: null
    }
  }
};

export const transactionsReducer = handleActions(
  {
    [actions.fetchLatestTransactions]: (state, { payload }) =>
      objectUtils.mergeDeepRight(state, {
        latestTransactions: {
          meta: {
            isFetching: true,
            fetchError: false
          }
        }
      }),
    [actions.fetchLatestTransactionsSuccess]: (state, { payload }) =>
      objectUtils.mergeDeepRight(state, {
        latestTransactions: {
          totalCount: payload.totalCount,
          page: payload.page,
          limit: payload.limit,
          byHash: payload.items.reduce((result, payloadItem) => {
            const transactionDetails = extractTransactionDetailsFromPayload(payloadItem);
            return { ...result, [payloadItem.hash]: transactionDetails };
          }, {}),
          allHashes: payload.items.map(payloadItem => payloadItem.hash),
          meta: {
            isFetching: false,
            fetchError: null
          }
        }
      }),
    [actions.fetchLatestTransactionsSuccessFromCache]: (state, { payload }) =>
      objectUtils.mergeDeepRight(state, {
        latestTransactions: {
          limit: payload.limit,
          allHashes: payload.allHashes,
          meta: {
            isFetching: false,
            fetchError: null
          }
        }
      }),
    [actions.fetchLatestTransactionsFailure]: (state, { payload }) =>
      objectUtils.mergeDeepRight(state, {
        latestTransactions: {
          meta: {
            isFetching: false,
            fetchError: payload
          }
        }
      }),
    [actions.fetchTransactionDetails]: (state, { payload }) =>
      objectUtils.mergeDeepRight(state, {
        transactionDetails: {
          meta: {
            isFetching: true
          }
        }
      }),
    [actions.fetchTransactionDetailsSuccess]: (state, { payload }) =>
      objectUtils.mergeDeepRight(state, {
        transactionDetails: {
          ...extractTransactionDetailsFromPayload(payload),
          meta: {
            isFetching: false,
            fetchError: undefined
          }
        }
      }),
    [actions.fetchTransactionDetailsFailure]: (state, { payload }) =>
      objectUtils.mergeDeepRight(state, {
        transactionDetails: {
          meta: {
            isFetching: false,
            fetchError: payload
          }
        }
      })
  },
  initialState
);

export const extractTransactionDetailsFromPayload = payload => ({
  hash: payload.hash,
  sender: payload.sender,
  recipient: payload.recipient,
  amount: payload.amount,
  fee: payload.fee,
  timestamp: payload.timestamp,
  blockNumber: payload.block_number
});
