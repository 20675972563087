import { useSelector } from "react-redux";
import useActions from "@utils/hooks/useActions";
import * as actions from "@graphs/actions";
import * as selectors from "@graphs/selectors";

export const useGraphSelections = () => {
  const selectedEntityType = useSelector(selectors.selectedEntityTypeSelector);
  const selectedEntityHash = useSelector(selectors.selectedEntityHashSelector);

  const selectedWalletTransactions = useSelector(
    selectors.selectedWalletTransactionsSelector
  );

  const selectedWalletTransactionHashes = useSelector(
    selectors.selectedWalletTransactionHashesSelector
  );

  const selectedWalletAdjacentWalletAddresses = useSelector(
    selectors.selectedWalletAdjacentWalletAddressesSelector
  );

  const selectedTransactionWalletAddresses = useSelector(
    selectors.selectedTransactionWalletAddressesSelector
  );

  const {
    selectWallet,
    expandWallet,

    selectTransaction,
    expandTransaction,
  } = useActions(actions);

  return {
    selectedEntityType,
    selectedEntityHash,

    selectedWalletTransactions,
    selectedWalletTransactionHashes,
    selectedWalletAdjacentWalletAddresses,
    selectedTransactionWalletAddresses,

    selectWallet,
    expandWallet,

    selectTransaction,
    expandTransaction,
  };
};
