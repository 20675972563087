import { createSelector } from "reselect";

export const routerSelector = state => state.router;

export const locationSelector = createSelector(
  routerSelector,
  router => router.location
);

export const pathnameSelector = createSelector(
  locationSelector,
  location => location.pathname
);
