// React
import React, { useMemo } from "react";
// Utils
import * as currencyUtils from "@utils/currencyUtils";
import * as eventUtils from "@utils/eventUtils";
// Hooks
import { useLatestGraphTransactions, useGraphSelections } from "@graphs/hooks";
// Components
import Table from "@common/components/Table";
import EntityNotFound from "@common/components/EntityNotFound";
import Loader from "@common/components/Loader";
import Paginator from "@common/components/Paginator";
// Constants
import * as constants from "@constants";
import { GRAPH_ENTITY_TYPES } from "@graphs/constants";
// Styles
import * as TableStyles from "@common/components/Table/styles";
import * as CommonStyles from "@common/styles";
import * as Styles from "./styles";

export const LatestGraphTransactions = ({ itemsPerPage, hidePagination }) => {
  const {
    transactions,
    page,
    pagesCount,
    highlightedPageIndexes,
    isFetching,
    fetchError,
    selectPage,
  } = useLatestGraphTransactions({
    itemsPerPage: constants.TRANSACTIONS_PER_PAGE,
    maxItemsCount: constants.MAX_GRAPH_TRANSACTIONS_ITEMS_COUNT,
  });

  const {
    selectedEntityType,
    selectedEntityHash,
    selectWallet,
    expandWallet,
    selectTransaction,
    expandTransaction,
  } = useGraphSelections();

  const columns = useMemo(
    () => [
      {
        title: "Hash",
        renderer: (tx) => (
          <Styles.Hash
            title={tx.hash}
            isUnderlined={
              selectedEntityType === GRAPH_ENTITY_TYPES.TRANSACTION &&
              selectedEntityHash === tx.hash
            }
            onMouseDown={eventUtils.preventSelectionOnDoubleClick}
            onDoubleClick={() => expandTransaction({ hash: tx.hash })}
            onClick={() =>
              selectedEntityHash === tx.hash
                ? expandTransaction({ hash: tx.hash })
                : selectTransaction({ hash: tx.hash })
            }
          >
            {tx.hash}
          </Styles.Hash>
        ),
        width: "20%",
      },
      {
        title: "Amount",
        renderer: (tx) => (
          <CommonStyles.Ellipsis>
            {currencyUtils.prettifyCurrencyString(tx.amount, 5)}
          </CommonStyles.Ellipsis>
        ),
        width: "20%",
      },
      {
        title: "Fee",
        renderer: (tx) => (
          <CommonStyles.Ellipsis>
            {currencyUtils.prettifyCurrencyString(tx.fee)}
          </CommonStyles.Ellipsis>
        ),
        width: "20%",
        hideOnMobile: true,
      },
      {
        title: "Sender",
        renderer: (tx) => (
          <Styles.Hash
            title={tx.from}
            isUnderlined={
              selectedEntityType === GRAPH_ENTITY_TYPES.WALLET &&
              selectedEntityHash === tx.from
            }
            onMouseDown={eventUtils.preventSelectionOnDoubleClick}
            onDoubleClick={() => expandWallet({ address: tx.from })}
            onClick={() =>
              selectedEntityHash === tx.from
                ? expandWallet({ address: tx.from })
                : selectWallet({ address: tx.from })
            }
          >
            {tx.from}
          </Styles.Hash>
        ),
        width: "20%",
      },
      {
        title: "Recipient",
        renderer: (tx) => (
          <Styles.Hash
            title={tx.to}
            isUnderlined={
              selectedEntityType === GRAPH_ENTITY_TYPES.WALLET &&
              selectedEntityHash === tx.to
            }
            onMouseDown={eventUtils.preventSelectionOnDoubleClick}
            onDoubleClick={() => expandWallet({ address: tx.to })}
            onClick={() =>
              selectedEntityHash === tx.to
                ? expandWallet({ address: tx.to })
                : selectWallet({ address: tx.to })
            }
          >
            {tx.to}
          </Styles.Hash>
        ),
        width: "20%",
      },
    ],
    [
      selectedEntityType,
      selectedEntityHash,
      selectWallet,
      expandWallet,
      selectTransaction,
      expandTransaction,
    ]
  );

  if (fetchError) {
    return <EntityNotFound name="Transactions" />;
  }

  return (
    <div>
      {(isFetching || transactions.length > 0) && (
        <>
          <CommonStyles.Header theme={constants.THEMES.DARK}>
            Latest Transactions
          </CommonStyles.Header>
          {isFetching ? (
            <Loader
              theme={constants.THEMES.DARK}
              minHeight={`${(1 + itemsPerPage) * TableStyles.ROW_HEIGHT_PX}px`}
            />
          ) : (
            <Table
              theme={constants.THEMES.DARK}
              columns={columns}
              items={transactions}
            />
          )}
        </>
      )}
      {!hidePagination && pagesCount > 1 && (
        <Paginator
          theme={constants.THEMES.DARK}
          pagesCount={pagesCount}
          currentPageIndex={page}
          highlightedPageIndexes={highlightedPageIndexes}
          onSelectPage={selectPage}
        />
      )}
    </div>
  );
};
