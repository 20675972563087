import styled from "styled-components";
import breakpoints from "@constants/breakpoints";

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-around;
  background: black;

  & > * {
    flex: 1;
    overflow: hidden;
  }

  @media (max-width: ${breakpoints.md}px) {
    flex-direction: column-reverse;
  }
`;

export const SearchAndTableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 800px;

  @media (max-width: ${breakpoints.md}px) {
    & > *:first-child {
      margin-top: 0;
    }
  }
`;

export const SearchWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const GraphWrapper = styled.div`
  max-width: 1000px;
`;
