import { put, takeLatest, select } from "redux-saga/effects";
import * as selectors from "@transactions/selectors";
import * as explorerApi from "@api/explorerApi";
import * as actions from "@transactions/actions";

export function* fetchLatestTransactions({ payload: { page, limit } }) {
  const currentPage = yield select(selectors.latestTransactionsPageSelector);
  const currentLimit = yield select(selectors.latestTransactionsLimitSelector);
  if (page === currentPage && limit < currentLimit) {
    const currentHashes = yield select(selectors.latestTransactionsAllHashesSelector);
    return yield put(
      actions.fetchLatestTransactionsSuccessFromCache({
        allHashes: currentHashes.slice(0, limit),
        limit
      })
    );
  }
  const { error, result } = yield* explorerApi.getLatestTransactions({
    skip: limit * (page - 1),
    limit: limit
  });
  if (error) {
    yield put(actions.fetchLatestTransactionsFailure(error));
  } else {
    yield put(
      actions.fetchLatestTransactionsSuccess({
        ...result,
        page,
        limit
      })
    );
  }
}

export function* watchFetchLatestTransactions() {
  yield takeLatest(actions.FETCH_LATEST_TRANSACTIONS, fetchLatestTransactions);
}

export function* fetchTransactionDetails({ payload: { address } }) {
  const { error, result } = yield* explorerApi.getTransactionDetails(address);
  if (error) {
    yield put(actions.fetchTransactionDetailsFailure(error));
  } else {
    yield put(actions.fetchTransactionDetailsSuccess(result));
  }
}

export function* watchFetchTransactionDetails() {
  yield takeLatest(actions.FETCH_TRANSACTION_DETAILS, fetchTransactionDetails);
}
