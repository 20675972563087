import { handleActions } from "redux-actions";
import * as constants from "@graphs/constants";
import * as actions from "@graphs/actions";
import * as objectUtils from "@utils/objectUtils";

const initialState = {
  walletVertices: [],
  transactionEdges: [],
  selectedEntityType: null,
  selectedEntityHash: null,
  meta: {
    isFetching: false,
    fetchError: null,
  },
};

export const graphsReducer = handleActions(
  {
    [actions.fetchGraph]: (state, { payload }) =>
      objectUtils.mergeDeepRight(state, {
        meta: {
          isFetching: true,
        },
      }),
    [actions.fetchGraphSuccess]: (state, { payload }) =>
      objectUtils.mergeDeepRight(state, {
        walletVertices: payload.vertices.map((payloadItem) => ({
          ...payloadItem,
          size: Number(payloadItem.size),
        })),
        transactionEdges: payload.edges,
        meta: {
          isFetching: false,
          fetchError: null,
        },
      }),
    [actions.fetchGraphFailure]: (state, { payload }) =>
      objectUtils.mergeDeepRight(state, {
        meta: {
          isFetching: false,
          fetchError: payload,
        },
      }),
    [actions.selectWallet]: (state, { payload }) =>
      objectUtils.mergeDeepRight(state, {
        selectedEntityType: constants.GRAPH_ENTITY_TYPES.WALLET,
        selectedEntityHash: payload.address,
      }),
    [actions.selectTransaction]: (state, { payload }) =>
      objectUtils.mergeDeepRight(state, {
        selectedEntityType: constants.GRAPH_ENTITY_TYPES.TRANSACTION,
        selectedEntityHash: payload.hash,
      }),
  },
  initialState
);
