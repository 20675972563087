// @ts-check

import { createSelector } from "reselect";
import { createPathSelector } from "@utils/selectorUtils";

const rootSelector = state => state.blocks;

const latestBlocksSelector = createPathSelector(rootSelector, "latestBlocks");
export const latestBlocksTotalCountSelector = createPathSelector(latestBlocksSelector, "totalCount");
export const latestBlocksPageSelector = createPathSelector(latestBlocksSelector, "page");
export const latestBlocksLimitSelector = createPathSelector(latestBlocksSelector, "limit");
export const latestBlocksByHashSelector = createPathSelector(latestBlocksSelector, "byHash");
export const latestBlocksAllHashesSelector = createPathSelector(latestBlocksSelector, "allHashes");
const latestBlocksMetaSelector = createPathSelector(latestBlocksSelector, "meta");
export const latestBlocksIsFetchingSelector = createPathSelector(latestBlocksMetaSelector, "isFetching");
export const latestBlocksFetchErrorSelector = createPathSelector(latestBlocksMetaSelector, "fetchError");
export const latestBlocksItemsSelector = createSelector(
  [latestBlocksByHashSelector, latestBlocksAllHashesSelector],
  (byHash, allHashes) => allHashes.map(hash => byHash[hash])
);

const blockDetailsSelector = createPathSelector(rootSelector, "blockDetails");
export const blockDetailsHeightSelector = createPathSelector(blockDetailsSelector, "height");
export const blockDetailsHashSelector = createPathSelector(blockDetailsSelector, "hash");
export const blockDetailsTimestampSelector = createPathSelector(blockDetailsSelector, "timestamp");
export const blockDetailsTransactionsCountSelector = createPathSelector(blockDetailsSelector, "transactionsCount");
export const blockDetailsAmountSelector = createPathSelector(blockDetailsSelector, "amount");
const blockDetailsMetaSelector = createPathSelector(blockDetailsSelector, "meta");
export const blockDetailsIsFetchingSelector = createPathSelector(blockDetailsMetaSelector, "isFetching");
export const blockDetailsFetchErrorSelector = createPathSelector(blockDetailsMetaSelector, "fetchError");

const blockTransactionsSelector = createPathSelector(rootSelector, "blockTransactions");
export const blockTransactionsTotalCountSelector = createPathSelector(blockTransactionsSelector, "totalCount");
export const blockTransactionsPageSelector = createPathSelector(blockTransactionsSelector, "page");
export const blockTransactionsByHashSelector = createPathSelector(blockTransactionsSelector, "byHash");
export const blockTransactionsAllHashesSelector = createPathSelector(blockTransactionsSelector, "allHashes");
export const blockTransactionsItemsSelector = createSelector(
  [blockTransactionsByHashSelector, blockTransactionsAllHashesSelector],
  (byHash, allHashes) => allHashes.map(hash => byHash[hash])
);
const blockTransactionsMetaSelector = createPathSelector(blockTransactionsSelector, "meta");
export const blockTransactionsIsFetchingSelector = createPathSelector(blockTransactionsMetaSelector, "isFetching");
export const blockTransactionsFetchErrorSelector = createPathSelector(blockTransactionsMetaSelector, "fetchError");
