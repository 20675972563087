// Libraries
import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
// Components
import { ContentPagesLayout } from "@app/layouts/ContentPagesLayout";
import { GraphPagesLayout } from "@app/layouts/GraphPagesLayout";
import StatsDashboard from "@stats/components/StatsDashboard";
import TransactionDetails from "@transactions/components/TransactionDetails";
import LatestTransactions from "@transactions/components/LatestTransactions";
import LatestBlocks from "@blocks/components/LatestBlocks";
import BlockDetails from "@blocks/components/BlockDetails";
import Wallet from "@wallets/components/Wallet";
import { GlobalTransactions } from "@graphs/components/dom/GlobalTransactions";
// Styles
import { GlobalStyle } from "@app/styles";
// Other
import * as routes from "@routes";
import * as constants from "@constants";

export const App = () => {
  return (
    <>
      <GlobalStyle />
      <Switch>
        <Route exact path={routes.INDEX}>
          {/* TODO: uncomment & remove redirect */}
          {/* <GraphPagesLayout>
            <GlobalTransactions />
          </GraphPagesLayout> */}
          <Redirect to={routes.LATEST_TRANSACTIONS} />
        </Route>
        <Route exact path={routes.GRAPH}>
          <GraphPagesLayout>
            <GlobalTransactions />
          </GraphPagesLayout>
        </Route>
        <Route exact path={routes.STATS}>
          <ContentPagesLayout>
            <StatsDashboard />
          </ContentPagesLayout>
        </Route>
        <Route exact path={routes.LATEST_BLOCKS}>
          <ContentPagesLayout>
            <LatestBlocks
              theme={constants.THEMES.LIGHT}
              itemsPerPage={constants.BLOCKS_PER_PAGE}
            />
          </ContentPagesLayout>
        </Route>
        <Route exact path={routes.LATEST_TRANSACTIONS}>
          <ContentPagesLayout>
            <LatestTransactions
              theme={constants.THEMES.LIGHT}
              itemsPerPage={constants.TRANSACTIONS_PER_PAGE}
            />
          </ContentPagesLayout>
        </Route>
        <Route exact path={`${routes.TRANSACTION}/:address`}>
          <ContentPagesLayout>
            <TransactionDetails />
          </ContentPagesLayout>
        </Route>
        <Route exact path={`${routes.BLOCK_DETAILS}/:hash`}>
          <ContentPagesLayout>
            <BlockDetails />
          </ContentPagesLayout>
        </Route>
        <Route exact path={`${routes.WALLET}/:address`}>
          <ContentPagesLayout>
            <Wallet />
          </ContentPagesLayout>
        </Route>
      </Switch>
    </>
  );
};
