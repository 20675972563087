import styled, { css } from "styled-components";
import { transparentize } from "polished";
import * as constants from "@constants";

export const OuterWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  z-index: 1;
`;

export const ActiveRowWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  color: ${({ isExpanded }) =>
    isExpanded ? constants.COLORS.ORANGE : constants.COLORS.BLACK};
`;

export const TriangleIconWrapper = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 10px;
  height: 10px;
  margin-left: 4px;
`;

export const TriangleIcon = styled.span`
  width: 0;
  height: 0;
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;
  border-bottom: 5px solid
    ${({ isExpanded }) =>
      isExpanded ? constants.COLORS.ORANGE : constants.COLORS.BLACK};

  transform: rotate(-180deg);

  ${({ isExpanded }) =>
    isExpanded &&
    css`
      position: relative;
      bottom: 1px;
      transform: rotate(0deg);
    `}
`;

export const Variant = styled.div`
  width: 100%;
  padding: 8px 14px;
  box-sizing: border-box;
  text-align: right;
  cursor: pointer;

  &:hover {
    background: ${transparentize(0.8, constants.COLORS.GRAY)};
  }
`;

export const ExpandedWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: absolute;
  right: 0;
  top: 26px;
  width: 80px;
  background: #ffffff;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.15);
  /* padding: 6px; */

  /* & > *:not(:first-child) {
    margin-top: 8px;
    cursor: pointer;

    &:hover {
      background: red;
    }
  } */
`;
