import * as stringUtils from "./stringUtils";
import * as functionUtils from "./functionUtils";

export const prettifyCurrencyString = (str, decimalPlaces = null) =>
  str &&
  functionUtils.pipe(
    str => str.trim(),
    str => (decimalPlaces ? Number(str).toFixed(decimalPlaces) : str),
    stringUtils.removeTrailingCharacter("0"),
    stringUtils.removeTrailingCharacter(".")
  )(str);
