import { useState, useEffect } from "react";
import breakpoints from "@constants/breakpoints";

const MD_MEDIA_QUERY = `(min-width: ${breakpoints.md}px)`;

const getScreenInfo = () => {
  const mdQueryResult = window.matchMedia(MD_MEDIA_QUERY).matches;
  return {
    isDesktopScreen: mdQueryResult,
    isMobileScreen: !mdQueryResult,
  };
};

export const useScreenInfo = () => {
  const [state, setState] = useState(() => getScreenInfo());
  useEffect(() => {
    const resizeHandler = () => setState(getScreenInfo());
    window.addEventListener("resize", resizeHandler);
    return () => window.removeEventListener("resize", resizeHandler);
  }, []);
  return state;
};
