import { handleActions } from "redux-actions";
import * as actions from "@stats/actions";
import * as objectUtils from "@utils/objectUtils";

const initialState = {
  transactionsHistory: {
    byDate: {},
    allDates: [],
    meta: {
      isFetching: false,
      fetchError: null
    }
  },
  priceHistory: {
    byDate: {},
    allDates: [],
    meta: {
      isFetching: false,
      fetchError: null
    }
  }
};

export const statsReducer = handleActions(
  {
    [actions.fetchTransactionsHistory]: (state, { payload }) =>
      objectUtils.mergeDeepRight(state, {
        transactionsHistory: {
          meta: {
            isFetching: true,
            fetchError: false
          }
        }
      }),
    [actions.fetchTransactionsHistorySuccess]: (state, { payload }) =>
      objectUtils.mergeDeepRight(state, {
        transactionsHistory: {
          byDate: payload.reduce(
            (result, payloadItem) => ({
              ...result,
              [payloadItem.date]: extractTransactionHistoryItemFromPayload(payloadItem)
            }),
            {}
          ),
          allDates: payload.map(payloadItem => payloadItem.date).reverse(),
          meta: {
            isFetching: false,
            fetchError: null
          }
        }
      }),
    [actions.fetchTransactionsHistoryFailure]: (state, { payload }) =>
      objectUtils.mergeDeepRight(state, {
        transactionsHistory: {
          meta: {
            isFetching: false,
            fetchError: payload
          }
        }
      }),
    [actions.fetchPriceHistory]: (state, { payload }) =>
      objectUtils.mergeDeepRight(state, {
        priceHistory: {
          meta: {
            isFetching: true,
            fetchError: false
          }
        }
      }),
    [actions.fetchPriceHistorySuccess]: (state, { payload }) =>
      objectUtils.mergeDeepRight(state, {
        priceHistory: {
          byDate: payload.reduce(
            (result, payloadItem) => ({
              ...result,
              [payloadItem.date]: extractPriceHistoryItemFromPayload(payloadItem)
            }),
            {}
          ),
          allDates: payload.map(payloadItem => payloadItem.date).reverse(),
          meta: {
            isFetching: false,
            fetchError: null
          }
        }
      }),
    [actions.fetchPriceHistoryFailure]: (state, { payload }) =>
      objectUtils.mergeDeepRight(state, {
        priceHistory: {
          meta: {
            isFetching: false,
            fetchError: payload
          }
        }
      })
  },
  initialState
);

export const extractTransactionHistoryItemFromPayload = payload => ({
  date: payload.date,
  amount: payload.amount
});

export const extractPriceHistoryItemFromPayload = payload => ({
  date: payload.date,
  high: payload.high,
  average: payload.avg,
  low: payload.low
});
