// React
import { useEffect } from "react";
import { useSelector } from "react-redux";
// Hooks
import useActions from "@utils/hooks/useActions";
// Actions
import * as transactionsActions from "@transactions/actions";
// Selectors
import * as transactionsSelectors from "@transactions/selectors";

export default function useTransactionDetails({ address: requestedAddress }) {
  const address = useSelector(transactionsSelectors.transactionDetailsHashSelector);
  const sender = useSelector(transactionsSelectors.transactionDetailsSenderSelector);
  const recipient = useSelector(transactionsSelectors.transactionDetailsRecipientSelector);
  const amount = useSelector(transactionsSelectors.transactionDetailsAmountSelector);
  const fee = useSelector(transactionsSelectors.transactionDetailsFeeSelector);
  const timestamp = useSelector(transactionsSelectors.transactionDetailsTimestampSelector);
  const blockNumber = useSelector(transactionsSelectors.transactionDetailsBlockNumberSelector);
  const isFetching = useSelector(transactionsSelectors.transactionDetailsIsFetchingSelector);
  const fetchError = useSelector(transactionsSelectors.transactionDetailsFetchErrorSelector);

  const { fetchTransactionDetails } = useActions(transactionsActions);

  // sync address & requestedAddress
  useEffect(() => {
    if (address !== requestedAddress) {
      fetchTransactionDetails({ address: requestedAddress });
    }
  }, [address, requestedAddress, fetchTransactionDetails]);

  return {
    address,
    sender,
    recipient,
    amount,
    fee,
    timestamp,
    blockNumber,
    isFetching,
    fetchError
  };
}
