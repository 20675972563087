// @ts-check

import { createAction } from "redux-actions";

export const FETCH_TRANSACTIONS_HISTORY = "FETCH_TRANSACTIONS_HISTORY";
export const FETCH_TRANSACTIONS_HISTORY_SUCCESS = "FETCH_TRANSACTIONS_HISTORY_SUCCESS";
export const FETCH_TRANSACTIONS_HISTORY_FAILURE = "FETCH_TRANSACTIONS_HISTORY_FAILURE";

export const fetchTransactionsHistory = createAction(FETCH_TRANSACTIONS_HISTORY);
export const fetchTransactionsHistorySuccess = createAction(FETCH_TRANSACTIONS_HISTORY_SUCCESS);
export const fetchTransactionsHistoryFailure = createAction(FETCH_TRANSACTIONS_HISTORY_FAILURE);

export const FETCH_PRICE_HISTORY = "FETCH_PRICE_HISTORY";
export const FETCH_PRICE_HISTORY_SUCCESS = "FETCH_PRICE_HISTORY_SUCCESS";
export const FETCH_PRICE_HISTORY_FAILURE = "FETCH_PRICE_HISTORY_FAILURE";

export const fetchPriceHistory = createAction(FETCH_PRICE_HISTORY);
export const fetchPriceHistorySuccess = createAction(FETCH_PRICE_HISTORY_SUCCESS);
export const fetchPriceHistoryFailure = createAction(FETCH_PRICE_HISTORY_FAILURE);
