import React from "react";
import styled from "styled-components";
import breakpoints from "@constants/breakpoints";
import { Link } from "react-router-dom";

export const Wrapper = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  min-height: 40px;
  max-width: 800px;
  padding-left: 40px;

  & > *:not(:first-child) {
    margin-left: 38px;
  }

  @media (max-width: ${breakpoints.md}px) {
    display: none;
  }
`;

export const EexExternalLink = styled.a`
  color: #ffffff;
  font-family: Roboto;
  font-size: 15px;
  line-height: 18px;
`;

export const EexInternalLink = styled(({ isActive, ...props }) => (
  <Link {...props} />
))`
  color: ${({ isActive }) => (isActive ? "#DB9E00" : "#FFFFFF")};
  font-family: Roboto;
  font-size: 15px;
  line-height: 18px;
`;
