// @ts-check

/**
 * @template T
 * @param {(arrayItem: T) => boolean} predicate
 * @returns {(array: T[]) => T[]}
 */
export const dropWhile = predicate => array => {
  const result = [];
  let i = 0;
  while (predicate(array[i])) {
    i += 1;
  }
  while (i < array.length) {
    result.push(array[i]);
    i += 1;
  }
  return result;
};

/**
 * @template T
 * @param {T[]} array
 * @returns {T[]}
 */
export const reverse = array => [...array].reverse();

/**
 *
 * @param {number} from
 * @param {number} to
 * @returns {number[]}
 */
export const range = (from, to) =>
  Array.from({ length: to - from + 1 }, (_, i) => from + i);

/**
 * @template T
 * @param {(arrayItem: T) => boolean} predicate
 * @returns {(array: T[]) => number[]}
 */
export const findAllIndexes = predicate => array => {
  const result = [];
  for (let index = 0; index < array.length; index++) {
    const item = array[index];
    if (predicate(item)) {
      result.push(index);
    }
  }
  return result;
};

/**
 * @template T
 * @template K
 * @param {(arrayItem: T) => K} keySelector
 * @param {T[]} array
 * @returns {T[]}
 */
export const removeDuplicates = (keySelector, array) => {
  const set = new Set();
  const result = [];
  for (const item of array) {
    const key = keySelector(item);
    if (!set.has(key)) {
      result.push(item);
      set.add(key);
    }
  }
  return result;
};

/**
 * @template T
 * @param {number} size
 * @param {T[]} array
 * @returns {T[][]}
 */
export const slidingWindow = (size, array) => {
  const result = [];
  const length = array.length - size + 1;
  for (let i = 0; i < length; i++) {
    result.push(array.slice(i, i + size));
  }
  return result;
};

/**
 * @template R
 * @param {(...args: any[]) => R} fn
 * @param  {...any[]} arrays
 * @returns {R[]}
 */
export const zipWith = (fn, ...arrays) => {
  const result = [];
  for (let index = 0; arrays.every(array => array.length > index); index++) {
    result.push(fn(...arrays.map(array => array[index])));
  }
  return result;
};
