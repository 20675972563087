// React
import React, { useState } from "react";
// Components
import Dropdown from "@common/components/Dropdown";
import Chart from "@common/components/Chart";
// Constants
import * as constants from "@constants";
// Styles
import * as CommonStyles from "@common/styles";
import * as Styles from "./styles";

export default function HistoryChart({ title, useHistoryData, lineDataKey, xAxisDataKey }) {
  const [chartPeriod, setChartPeriod] = useState(constants.DEFAULT_CHART_PERIOD_VARIANT.value);
  const { historyData } = useHistoryData(chartPeriod);
  return (
    <div>
      <Styles.HeaderRow margin="50px 0 22px 0">
        <CommonStyles.Header margin="0">{title}</CommonStyles.Header>
        <Dropdown variants={constants.CHART_PERIOD_VARIANTS} value={chartPeriod} onChange={setChartPeriod} />
      </Styles.HeaderRow>
      <CommonStyles.Card.Wrapper padding="0">
        <Chart data={historyData} lineDataKey={lineDataKey} xAxisDataKey={xAxisDataKey} />
      </CommonStyles.Card.Wrapper>
    </div>
  );
}
