// @ts-check

import * as dateFns from "date-fns";
import * as arrayUtils from "@utils/arrayUtils";
import { apiCaller } from "./api";

const apiCall = apiCaller(process.env.REACT_APP_EXPLORER_API_URL);

export const getTransactionDetails = (hash) => apiCall(`/tx/${hash}`);

export const getWalletDetails = (address) =>
  apiCall(`/wallet/${address}?limit=0`);

export const getBlockDetails = (address) =>
  apiCall(`/block/${address}?limit=0`);

export const getWalletTransactions = (address, { skip, limit }) =>
  apiCall(`/wallet/${address}/txs?offset=${skip}&limit=${limit}`, {
    resultTransformer: (json, headers) => ({
      totalCount: headers.get("x-total-count"),
      items: json,
    }),
  });

export const getBlockTransactions = (hash, { skip, limit }) =>
  apiCall(`/block/${hash}/txs?offset=${skip}&limit=${limit}`, {
    resultTransformer: (json, headers) => ({
      totalCount: headers.get("x-total-count"),
      items: json,
    }),
  });

export const getLatestTransactions = ({ skip, limit }) =>
  apiCall(`/txs?offset=${skip}&limit=${limit}`, {
    resultTransformer: (json, headers) => ({
      totalCount: headers.get("x-total-count"),
      items: json,
    }),
  });

export const getLatestBlocks = ({ skip, limit }) =>
  apiCall(`/blocks?offset=${skip}&limit=${limit}`, {
    resultTransformer: (json, headers) => ({
      totalCount: headers.get("x-total-count"),
      items: json,
    }),
  });

export const getTransactionsHistory = ({ limit }) =>
  apiCall(`/txs/history?limit=${limit}`, {
    resultTransformer: (payload) => {
      // TODO: remove this when it's implemented on backend side
      if (payload.length === 0) return [];
      const dateFormat = "yyyy-MM-dd";
      const now = new Date();
      const backendData = new Map(payload.map((item) => [item.date, item]));
      const datesOfInterest = arrayUtils
        .range(0, limit - 1)
        .map((index) => dateFns.subDays(now, index));
      return datesOfInterest.map((date) => {
        const formattedDate = dateFns.format(date, dateFormat);
        return {
          date: formattedDate,
          amount: backendData.get(formattedDate)?.amount ?? 0,
        };
      });
    },
  });

export const getPriceHistory = ({ limit }) =>
  apiCall(`/rates/history?limit=${limit}`, {
    resultTransformer: (payload) => {
      // TODO: remove this when it's implemented on backend side
      if (payload.length === 0) return [];
      const dateFormat = "yyyy-MM-dd";
      const now = new Date();
      const formattedNow = dateFns.format(now, dateFormat);
      const payloadDataByFormattedDate = new Map(
        payload.map((item) => [item.date, item])
      );
      const payloadFormattedDates = payload.map((item) => item.date);
      const firstPayloadFormattedDate = payloadFormattedDates[0];
      const formattedDateSegmentsPrefix =
        formattedNow > firstPayloadFormattedDate
          ? [[formattedNow, firstPayloadFormattedDate]]
          : [];
      const lastDateOfInterest = dateFns.subDays(now, limit);
      const formattedLastDayOfInterest = dateFns.format(
        lastDateOfInterest,
        dateFormat
      );
      const payloadFormattedDateSegments = arrayUtils.slidingWindow(
        2,
        payloadFormattedDates
      );
      const payloadFormattedDateSegmentsOfInterest = payloadFormattedDateSegments
        .filter(([end, _]) => end >= formattedLastDayOfInterest)
        .map(([end, start]) => [
          end,
          start > formattedLastDayOfInterest
            ? start
            : formattedLastDayOfInterest,
        ]);
      const [
        ,
        lastPayloadFormattedDateSegmentOfInterestStart,
      ] = payloadFormattedDateSegmentsOfInterest[
        payloadFormattedDateSegmentsOfInterest.length - 1
      ];
      const formattedDateSegmentsSuffix =
        lastPayloadFormattedDateSegmentOfInterestStart >
        formattedLastDayOfInterest
          ? [
              [
                lastPayloadFormattedDateSegmentOfInterestStart,
                formattedLastDayOfInterest,
              ],
            ]
          : [];
      const formattedDateSegments = [
        ...formattedDateSegmentsPrefix,
        ...payloadFormattedDateSegmentsOfInterest,
        ...formattedDateSegmentsSuffix,
      ];
      const mostRecentPayloadAvg = payload[0].avg;
      const oldestPayloadAvg = payload[payload.length - 1].avg;
      return [].concat(
        ...formattedDateSegments.map(([end, start]) => {
          const segmentEndDate = new Date(end);
          const segmentStartDate = new Date(start);
          const segmentEndAvg =
            payloadDataByFormattedDate.get(end)?.avg ?? mostRecentPayloadAvg;
          const segmentStartAvg =
            payloadDataByFormattedDate.get(start)?.avg ?? oldestPayloadAvg;
          const segmentAvg = (segmentEndAvg + segmentStartAvg) / 2;
          return arrayUtils
            .range(
              0,
              dateFns.differenceInDays(segmentEndDate, segmentStartDate) - 1
            )
            .map((index) => {
              const date = dateFns.subDays(segmentEndDate, index);
              const formattedDate = dateFns.format(date, dateFormat);
              const avg =
                payloadDataByFormattedDate.get(formattedDate)?.avg ??
                segmentAvg;
              return { date: formattedDate, avg };
            });
        })
      );
    },
  });

export const getGraphData = () => apiCall("/graph");
