// React
import React from "react";
// Components
import LatestTransactions from "@transactions/components/LatestTransactions";
import LatestBlocks from "@blocks/components/LatestBlocks";
import PriceHistoryChart from "@stats/components/PriceHistoryChart";
import TransactionsHistoryChart from "@stats/components/TransactionsHistoryChart";
// Routes
import * as routes from "@routes";
// Constants
import * as constants from "@constants";
// Styles
import * as Styles from "./styles";

export default function StatsDashboard() {
  return (
    <Styles.Wrapper>
      <Styles.Row>
        <PriceHistoryChart />
        <Styles.RowSpacer />
        <TransactionsHistoryChart />
      </Styles.Row>
      <Styles.Row>
        <div>
          <LatestBlocks
            theme={constants.THEMES.LIGHT}
            itemsPerPage={constants.DASHBOARD_TABLES_ITEMS_PER_PAGE}
            hidePagination={true}
          />
          <Styles.MoreDetailsWrapper>
            <Styles.MoreDetailsLink to={routes.LATEST_BLOCKS}>More details ⟶</Styles.MoreDetailsLink>
          </Styles.MoreDetailsWrapper>
        </div>
        <Styles.RowSpacer />
        <div>
          <LatestTransactions
            theme={constants.THEMES.LIGHT}
            itemsPerPage={constants.DASHBOARD_TABLES_ITEMS_PER_PAGE}
            hidePagination={true}
          />
          <Styles.MoreDetailsWrapper>
            <Styles.MoreDetailsLink to={routes.LATEST_TRANSACTIONS}>More details ⟶</Styles.MoreDetailsLink>
          </Styles.MoreDetailsWrapper>
        </div>
      </Styles.Row>
    </Styles.Wrapper>
  );
}
