// @ts-check

import { createAction } from "redux-actions";

export const FETCH_LATEST_TRANSACTIONS = "FETCH_LATEST_TRANSACTIONS";
export const FETCH_LATEST_TRANSACTIONS_FAILURE = "FETCH_LATEST_TRANSACTIONS_FAILURE";
export const FETCH_LATEST_TRANSACTIONS_SUCCESS = "FETCH_LATEST_TRANSACTIONS_SUCCESS";
export const FETCH_LATEST_TRANSACTIONS_SUCCESS_FROM_CACHE = "FETCH_LATEST_TRANSACTIONS_SUCCESS_FROM_CACHE";

export const fetchLatestTransactions = createAction(FETCH_LATEST_TRANSACTIONS);
export const fetchLatestTransactionsFailure = createAction(FETCH_LATEST_TRANSACTIONS_FAILURE);
export const fetchLatestTransactionsSuccess = createAction(FETCH_LATEST_TRANSACTIONS_SUCCESS);
export const fetchLatestTransactionsSuccessFromCache = createAction(FETCH_LATEST_TRANSACTIONS_SUCCESS_FROM_CACHE);

export const FETCH_TRANSACTION_DETAILS = "FETCH_TRANSACTION_DETAILS";
export const FETCH_TRANSACTION_DETAILS_FAILURE = "FETCH_TRANSACTION_DETAILS_FAILURE";
export const FETCH_TRANSACTION_DETAILS_SUCCESS = "FETCH_TRANSACTION_DETAILS_SUCCESS";

export const fetchTransactionDetails = createAction(FETCH_TRANSACTION_DETAILS);
export const fetchTransactionDetailsFailure = createAction(FETCH_TRANSACTION_DETAILS_FAILURE);
export const fetchTransactionDetailsSuccess = createAction(FETCH_TRANSACTION_DETAILS_SUCCESS);
