import styled from "styled-components";

export const Ellipsis = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  ${({ display }) => display && `display: ${display};`}
  ${({ maxWidth }) => maxWidth && `max-width: ${maxWidth};`}
`;
