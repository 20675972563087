// @ts-check

import { createSelector } from "reselect";
import { createPathSelector } from "@utils/selectorUtils";

const rootSelector = state => state.transactions;

const latestTransactionsSelector = createPathSelector(rootSelector, "latestTransactions");
export const latestTransactionsTotalCountSelector = createPathSelector(latestTransactionsSelector, "totalCount");
export const latestTransactionsPageSelector = createPathSelector(latestTransactionsSelector, "page");
export const latestTransactionsLimitSelector = createPathSelector(latestTransactionsSelector, "limit");
export const latestTransactionsMetaSelector = createPathSelector(latestTransactionsSelector, "meta");
export const latestTransactionsIsFetchingSelector = createPathSelector(latestTransactionsMetaSelector, "isFetching");
export const latestTransactionsFetchErrorSelector = createPathSelector(latestTransactionsMetaSelector, "fetchError");
export const latestTransactionsByHashSelector = createPathSelector(latestTransactionsSelector, "byHash");
export const latestTransactionsAllHashesSelector = createPathSelector(latestTransactionsSelector, "allHashes");

export const latestTransactionsItemsSelector = createSelector(
  [latestTransactionsByHashSelector, latestTransactionsAllHashesSelector],
  (byHash, allHashes) => allHashes.map(hash => byHash[hash])
);

export const transactionDetailsSelector = createPathSelector(rootSelector, "transactionDetails");
export const transactionDetailsHashSelector = createPathSelector(transactionDetailsSelector, "hash");
export const transactionDetailsSenderSelector = createPathSelector(transactionDetailsSelector, "sender");
export const transactionDetailsRecipientSelector = createPathSelector(transactionDetailsSelector, "recipient");
export const transactionDetailsAmountSelector = createPathSelector(transactionDetailsSelector, "amount");
export const transactionDetailsFeeSelector = createPathSelector(transactionDetailsSelector, "fee");
export const transactionDetailsTimestampSelector = createPathSelector(transactionDetailsSelector, "timestamp");
export const transactionDetailsBlockNumberSelector = createPathSelector(transactionDetailsSelector, "blockNumber");
export const transactionDetailsMetaSelector = createPathSelector(transactionDetailsSelector, "meta");
export const transactionDetailsIsFetchingSelector = createPathSelector(transactionDetailsMetaSelector, "isFetching");
export const transactionDetailsFetchErrorSelector = createPathSelector(transactionDetailsMetaSelector, "fetchError");
