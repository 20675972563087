import styled from "styled-components";
import breakpoints from "@constants/breakpoints";
import { Link } from "react-router-dom";

const SHADOW_FIX_OFFSET = 4;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: -${SHADOW_FIX_OFFSET}px;
  margin-bottom: -${SHADOW_FIX_OFFSET}px;
  margin-left: -${SHADOW_FIX_OFFSET}px;
  margin-right: -${SHADOW_FIX_OFFSET}px;
`;

export const Row = styled.div`
  display: flex;

  & > * {
    /* This is needed to make charts resize properly */
    flex: 1;
    /* This is needed to fix shadows because of flex */
    padding: ${SHADOW_FIX_OFFSET}px;
    overflow: hidden;
  }

  @media (max-width: ${breakpoints.lg}px) {
    flex-direction: column;
  }
`;

export const RowSpacer = styled.div`
  flex: 0;
  min-width: 50px;
  box-sizing: border-box;

  @media (max-width: ${breakpoints.lg}px) {
    display: none;
  }
`;

export const MoreDetailsWrapper = styled.div`
  margin-top: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
`;

export const MoreDetailsLink = styled(Link)`
  color: #1d1d1d;
`;
