import React from "react";
import * as Styles from "./styles";

export default function Table({ columns, items, theme }) {
  return (
    <Styles.Table theme={theme}>
      <thead>
        <Styles.TableRow>
          {columns.map((column, index) => (
            <Styles.TableHeaderCell
              key={index}
              width={column.width}
              hideOnMobile={column.hideOnMobile}
            >
              {column.title}
            </Styles.TableHeaderCell>
          ))}
        </Styles.TableRow>
      </thead>
      <tbody>
        {items.map((item, index) => (
          <Styles.TableRow
            key={index}
            index={index}
            theme={theme}
            isSelected={item.isSelected}
          >
            {columns.map((column, index) =>
              column.renderer == null ? (
                <Styles.TableDataCell
                  key={index}
                  hideOnMobile={column.hideOnMobile}
                >
                  {item[column.key]}
                </Styles.TableDataCell>
              ) : (
                <Styles.TableDataCell
                  key={index}
                  hideOnMobile={column.hideOnMobile}
                >
                  {column.renderer(item)}
                </Styles.TableDataCell>
              )
            )}
          </Styles.TableRow>
        ))}
      </tbody>
    </Styles.Table>
  );
}
